import { z } from 'zod'

const numberWithTwoDecimals = z.number().refine(
  (value) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [integerPart, decimalPart] = value.toString().split('.')
    return !decimalPart || decimalPart.length <= 2
  },
  {
    message: 'Number must have at most two decimal places',
  },
)

export const RecipientSchema = z.object({
  id: z.string(),
  name: z.string(),
  address: z.string(),
  tokenAllocation: numberWithTwoDecimals,
  tokenAllocationPercentage: z.number(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullable(),
  capitalizationId: z.string(),
})

export const RecipientBody = RecipientSchema.omit({
  id: true,
  tokenAllocationPercentage: true,
  createdAt: true,
  updatedAt: true,
  deletedAt: true,
})

export type RecipientSchemaType = z.infer<typeof RecipientSchema>
export type RecipientBodyType = z.infer<typeof RecipientBody>

// TODO: consolidate this with the one in libs/common/src/types/tokenomics/index.ts
export const CapitalizationSchemas = z.object({
  id: z.string(),
  tokenomicsId: z.string(),
  name: z.string(),
  allocation: z.number(),
  costBasisPerToken: z.number(),
  unlockStart: z.date(),
  unlockEnd: z.date(),
  unlockFrequency: z.number(),
  initialUnlock: z.number(),
  potentialSeller: z.boolean(),
  autoDistributionEnabled: z.boolean(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.union([z.date().nullable(), z.null()]),
  recipients: z.array(RecipientSchema),
  __entity: z.literal('Capitalization').optional(),
})

export type CapitalizationSchemasType = z.infer<typeof CapitalizationSchemas>

export const CapitalizationSchemasWithAmount = CapitalizationSchemas.extend({
  allocationAmount: z.number().optional(),
})

export type CapitalizationSchemasWithAmountType = z.infer<
  typeof CapitalizationSchemasWithAmount
>

export const RecipientResponseBody = z.object({
  capitalizationId: z.string(),
  name: z.string(),
  address: z.string(),
  tokenAllocation: z.number(),
  tokenAllocationPercentage: z.number(),
  capitalization: z.object({
    id: z.string(),
    tokenomicsId: z.string(),
    name: z.string(),
    allocation: z.number(),
    costBasisPerToken: z.number(),
    unlockStart: z.date(),
    unlockEnd: z.date(),
    unlockFrequency: z.number(),
    initialUnlock: z.number(),
    potentialSeller: z.boolean(),
    autoDistributionEnabled: z.boolean(),
    createdAt: z.date(),
    updatedAt: z.date(),
    deletedAt: z.union([z.date().nullable(), z.null()]),
  }),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullable(),
  id: z.string(),
  __entity: z.literal('Recipient'),
})

export const PostADBody = z.object({
  tokenomicsId: z.string(),
})

export type PostADBodyType = z.infer<typeof PostADBody>

export const PostADResult = z.object({
  id: z.string(),
  inReview: z.boolean(),
  confirmed: z.boolean(),
  deployed: z.boolean(),
  contractAddress: z.string().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullable(),
  tokenomics: z.object({
    id: z.string(),
    projectId: z.string(),
    totalTokenSupply: z.number(),
    dateOfTGE: z.date(),
    priceAtTGE: z.number(),
    anticipatedReturn: z.number(),
    estimatedLiquidity: z.number(),
    estimatedGrowth: z.number(),
    createdAt: z.date(),
    updatedAt: z.date(),
    deletedAt: z.date().nullable(),
    capitalizations: z.array(CapitalizationSchemas),
    __entity: z.literal('Tokenomics'),
  }),
  __entity: z.literal('AutoDistribution'),
})

export type PostADResultType = z.infer<typeof PostADResult>

export const ADRecipientSummary = z.object({
  recipient: z.string(),
  walletAddress: z.string(),
  initialUnlockPercentage: z.number(),
  recipientTokenAllocationPercentage: z.number(),
  recipientTokenAllocation: z.number(),
  unlockedClaimedPercentage: z.number(),
  unlockedClaimed: z.number(),
})

export const ADGroup = z.object({
  group: z.string(),
  unlockFrequency: z.string(),
  unlockDuration: z.string(),
  initialUnlockPercentage: z.number(),
  unlockStart: z.date(),
  groupTokenSupplyPercentage: z.number(),
  groupTokenSupply: z.number(),
  unlockedClaimedPercentage: z.number(),
  unlockedClaimed: z.number(),
  progress: z.number(),
  status: z.string(),
  eligible: z.boolean(),
  recipients: z.array(ADRecipientSummary),
})

export const ADSummary = z.object({
  autoDistributionStatus: z.string(),
  autoDistributionProgress: z.number(),
  status: z.string(),
  TGEDate: z.date(),
  countDownToTGe: z.number().nullable(),
  endDate: z.date().nullable(),
  estimatedDurationMonths: z.number().nullable(),
  estimatedDurationDays: z.number().nullable(),
  unlockedTokensFromAutoDistribution: z.number(),
  circulatingSupply: z.number(),
  marketCap: z.number(),
  price: z.number(),
  twentyFourHourChange: z.number(),
  percentageOfTokensUnlocked: z.number(),
  smartContractAddress: z.string().nullable(),
  smartContractStatus: z.string().nullable(),
  smartContractBalance: z.number().nullable(),
  totalGroups: z.number(),
  autoDistributionGroups: z.number(),
  individuals: z.number(),
  groups: z.array(ADGroup),
})

export type ADSummary = z.infer<typeof ADSummary>
export type ADGroup = z.infer<typeof ADGroup>
export type ADRecipientSummary = z.infer<typeof ADRecipientSummary>

import { z } from 'zod'
import { TypeID } from 'typeid-js'

export const MonthlySupplyDemandDelta = z.object({
  delta: z.number(),
  demand: z.number(),
  month: z.number(),
  supply: z.number(),
})

export type MonthlySupplyDemandDelta = z.infer<typeof MonthlySupplyDemandDelta>

export const AdjustingPreviewResponse = z.array(
  z.object({
    slug: z.string(),
    data: z.array(MonthlySupplyDemandDelta),
  }),
)

export type AdjustingPreviewResponse = z.infer<typeof AdjustingPreviewResponse>

export const SimulatingPostTGEPop = z.object({
  postTGEPopMultiplier: z.number().default(1),
})
export type SimulatingPostTGEPop = z.infer<typeof SimulatingPostTGEPop>

export const EmptyField = z.object({
  empty: z.boolean().default(true),
})
export type EmptyField = z.infer<typeof EmptyField>

export const AdjustingFieldsSchema = z.union([SimulatingPostTGEPop, EmptyField])

export type AdjustingFieldsSchema = z.infer<typeof AdjustingFieldsSchema>

// Should be a union when more than 1 schema is added
const validAdjustingSubsectionIds = z.union([
  z.literal('modelingsupplyanddemand'),
  z.literal('optimizingcirculatingsupply'),
  z.literal('modelingsellpressure'),
  z.literal('simulatingpricediscovery'),
  z.literal('simulatingposttgepops'),
  z.literal('simulatingpricediscoverywithadvancedvariables'),
])

const adjustingSchemaMap = {
  modelingsupplyanddemand: EmptyField,
  optimizingcirculatingsupply: EmptyField,
  modelingsellpressure: EmptyField,
  simulatingpricediscovery: EmptyField,
  simulatingposttgepops: SimulatingPostTGEPop,
  simulatingpricediscoverywithadvancedvariables: EmptyField,
}

export const PutAdjustingBody = z.object({
  subSectionId: z.string(),
  fields: AdjustingFieldsSchema,
})

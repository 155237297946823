import { z } from 'zod'
import { customDateSchema } from '../common'

export const PostMarketMakerSchema = z.object({
  projectId: z.string(),
  name: z.string(),
  ticker: z.string(),
  website: z.string(),
  dateOfTGE: customDateSchema,
  tokenListed: z.boolean(),
  workingWithMarketMaker: z.boolean(),
  exploreSelfServiceSolution: z.boolean(),
  additional: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
})

export const PostMarketMakerResult = z.object({
  id: z.string(),
  projectId: z.string(),
  name: z.string(),
  ticker: z.string().nullable(),
  website: z.string(),
  dateOfTGE: z.date(),
  tokenListed: z.boolean(),
  workingWithMarketMaker: z.boolean(),
  exploreSelfServiceSolution: z.boolean(),
  additional: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullable(),
})

export const GetActiveMarketMakerDetailsQuery = z.object({
  projectId: z.string({ required_error: 'projectId is required' }).uuid(),
  mmid: z.string({ required_error: 'market maker id is required' }).uuid(),
  date: z.string().refine(
    (value) => {
      const date = new Date(value)
      return !isNaN(date.getTime())
    },
    {
      message: 'Invalid date format. Must be YYYY-MM-DD',
    },
  ),
})

const LiquidityBreakdownsByExchange = z.object({
  exchange: z.string(),
  allmmask50bps: z.number(),
  allmmask50bpspercentage: z.number(),
  allmmbid50bps: z.number(),
  allmmbid50bpspercentage: z.number(),
  allmmbidask50bps: z.number(),
  allmmbidask50bpspercentage: z.number(),
  allmmask100bps: z.number(),
  allmmask100bpspercentage: z.number(),
  allmmbid100bps: z.number(),
  allmmbid100bpspercentage: z.number(),
  allmmbidask100bps: z.number(),
  allmmbidask100bpspercentage: z.number(),
  allmmask200bps: z.number(),
  allmmask200bpspercentage: z.number(),
  allmmbid200bps: z.number(),
  allmmbid200bpspercentage: z.number(),
  allmmbidask200bps: z.number(),
  allmmbidask200bpspercentage: z.number(),
})

const LiquidityBreakdownsByExchangeResult = z.array(
  LiquidityBreakdownsByExchange,
)

export const GetActiveMarketMakerDetails = z.object({
  type: z.enum(['PMM', 'DMM']),
  totalDaysTracked: z.number(),
  activeCex: z.number(),
  activeDex: z.number(),
  avgFillVolume: z.number(),
  avgDepth: z.number(),
  avgSpread: z.number(),
  avgVolumeBasedLoanUtil: z.number(),
  avgLiquidityBasedLoanUtil: z.number(),
  percentageOfTotalVolume: z.number(),
  percentageOfTotalLiquidity: z.number(),
  totalLiquidity: z.number(),
  breakdownByExchange: LiquidityBreakdownsByExchangeResult,
  totalVolume: z.number(),
  loanSize: z.number(),
})

export const GetActiveMarketMakerDetailsResult = z.union([
  GetActiveMarketMakerDetails,
  z.null(),
])

export type ActiveMarketMakerDetails = z.infer<
  typeof GetActiveMarketMakerDetailsResult
>

import { z } from 'zod'
import { metaSchema } from '../pagination'

export const Organization = z.object({
  id: z.string().uuid(),
  name: z.string(),
  ownerUserId: z.string(),
  timeZone: z.string(),
  country: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date(),
})

export const Organizations = z.object({
  items: z.array(Organization),
  meta: metaSchema,
})
export const country = z.object({
  country: z.string(),
  iso2Code: z.string(),
  iso3Code: z.string(),
  officialLanguage: z.string(),
})

export const TCountriesTemplates = z.array(country)
export type TCountriesType = z.infer<typeof TCountriesTemplates>

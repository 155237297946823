import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import { GetMeResponse, TUpdateUser, UserResponse } from '../types'

const c = initContract()

export const usersContract = c.router({
  me: {
    method: 'GET',
    path: `users/me`,
    responses: {
      200: GetMeResponse,
    },
    summary: 'Get the current user',
  },
  updateUser: {
    method: 'PATCH',
    path: `users/me`,
    responses: {
      200: UserResponse,
    },
    body: TUpdateUser,
    summary: 'Update the current user',
  },
  onboardUser: {
    method: 'POST',
    path: `users/me/onboard`,
    responses: {
      200: UserResponse,
    },
    body: z.object({
      projectId: z.string().uuid(),
    }),
    summary: 'Onboard the current user',
  },
})

export type UsersContractType = typeof usersContract

import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import { PublicPageResponse } from '../types'

const c = initContract()

export const tokenDesignerPublicPage = c.router({
  getTDPublicPage: {
    method: 'GET',
    path: `token-designer/public-page`,
    query: z.object({
      projectId: z.string({ required_error: 'projectId is required' }).uuid(),
    }),
    // body: z.object({
    //   password: z.string(),
    // }),
    responses: {
      200: PublicPageResponse,
    },
    summary: 'Get token designer public page',
  },
})

export type TokenDesignerPublicPageContractType = typeof tokenDesignerPublicPage

import { z } from 'zod'
import { TypeID } from 'typeid-js'
import {
  TokenDistributionPreview,
  TokenEmissionPreview,
} from './modeling.types'

export const ComparableProject = z.object({
  image: z.optional(z.string().nullable()),
  name: z.string(),
  FDV: z.number(),
  MC: z.number(),
})
export type ComparableProject = z.infer<typeof ComparableProject>
export const ComparableProjects = z.array(ComparableProject)
export type ComparableProjects = z.infer<typeof ComparableProjects>

export const Configurations = z.object({
  achieveFDVPercentage: z.number(),
  projectedFDV: z.number(),
  FDV: z.number(),
  MTS: z.number(),
})
export type Configurations = z.infer<typeof Configurations>

export const ComparableConfigurations = z.object({
  comparables: ComparableProjects,
  configurations: Configurations,
})
export type ComparableConfigurations = z.infer<typeof ComparableConfigurations>

// TODO sync with ui/pages/token-designer/valuation-documenting/sanitizing-tokens.vue until @forgd/common is pure
export const ProjectDetails = z.object({
  capitalRaised: z.number().nullable(),
  dateOfTGE: z.string().min(1),
  existingInvestors: z.string().nullable(),
  fdvFromPreviousFundraising: z.number().nullable(),
  founderBio: z.string().nullable(),
  founderName: z.string().nullable(),
  marketMakers: z.string().min(1),
  officialWebsite: z.string().min(1),
  password: z.string().optional(),
  sector: z.string().min(1),
  seniorTeamMembers: z.string().nullable(),
  summary: z.string().min(1),
  targetCEXs: z.string().min(1),
  typeOfMarketMaker: z.string().min(1),
  published: z.boolean().default(false),
})
export type ProjectDetails = z.infer<typeof ProjectDetails>

export const ValuationFieldsSchema = z.union([
  ComparableConfigurations,
  ProjectDetails,
])
export type ValuationFieldsSchema = z.infer<typeof ValuationFieldsSchema>

export const Valuation = z.object({
  distribution: TokenDistributionPreview,
  emissions: TokenEmissionPreview.nullable(),
})
export type Valuation = z.infer<typeof Valuation>

// Union of all modeling schemas
export const ValuationPreview = Valuation
export type ValuationPreview = z.infer<typeof ValuationPreview>

export const ValuationPreviewResponse = z.array(
  z.object({
    slug: z.string(),
    data: ValuationPreview.nullable(),
  }),
)

export type ValuationPreviewResponse = z.infer<typeof ValuationPreviewResponse>

const validValuationSubsectionIds = z.union([
  z.literal('fdvmaximumtokensupply'),
  z.literal('sanitizingtokenomics'),
])

const valuationSchemaMap = {
  fdvmaximumtokensupply: ComparableConfigurations,
  sanitizingtokenomics: ProjectDetails,
}

export const PutValuationBody = z.object({
  subSectionId: z.string(),
  fields: z.unknown(),
})

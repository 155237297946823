import { z } from 'zod'
import { TProject, TUpdateProjectBody } from '../projects'
import { MemberContactType, MemberInvitationDto } from '../membership'
import {
  AreasOfInterestEnum,
  LeadSourceTypeEnum,
} from '../../supabase/supabase.enums'

export const InitProjectBody = z.object({
  organizationId: z.string().uuid(),
  isResearch: z.boolean(),
})
export const InitProjectResponse = TProject

export const UpdateProjectTypeBody = z.object({
  projectId: z.string().uuid(),
  isResearch: z.boolean(),
})

export const UpdateProjectTypeResponse = TProject

const areasOfInterestEnum = z.nativeEnum(AreasOfInterestEnum)

export const UpdateProjectDetailsBody = TUpdateProjectBody.extend({
  projectId: z.string().uuid(),
})

export const UpdateAreasOfInterestBody = z.object({
  projectId: z.string().uuid(),
  areasOfInterest: z.array(areasOfInterestEnum),
})

export const OwnerDetailsBody = z.object({
  projectId: z.string().uuid(),
  firstName: z.string(),
  lastName: z.string(),
  organizationName: z.string(),
  position: z.string(),
  contactType: z.nativeEnum(MemberContactType),
  contact: z.string().min(1),
  leadSourceType: z.nativeEnum(LeadSourceTypeEnum),
})

export const CompleteBody = z.object({
  projectId: z.string().uuid(),
  members: z.array(MemberInvitationDto),
})

export const GetProgressResponseBody = z.object({
  projectType: z.boolean(),
  projectDetails: z.boolean(),
  areasOfInterest: z.boolean(),
  ownerDetails: z.boolean(),
  memberInvitations: z.boolean(),
})

export const MemberDetailsBody = OwnerDetailsBody.omit({
  leadSourceType: true,
}).extend({
  email: z
    .string()
    .email()
    .transform((email) => email.toLowerCase()),
})

export const GetMemberInvitesResponseBody = z.object({
  members: z.array(MemberDetailsBody),
  maxSeats: z.number(),
})

export const GetMemberDetailsResponseBody = MemberDetailsBody

export const GetOwnerDetailsResponseBody = OwnerDetailsBody

export const GetAreasOfInterestResponseBody = UpdateAreasOfInterestBody

export const GetProjectDetailsResponseBody = z.union([TProject, z.null()])

export type OwnerDetails = z.infer<typeof OwnerDetailsBody>
export type MemberDetails = z.infer<typeof MemberDetailsBody>
export type OnboardingProgress = z.infer<typeof GetProgressResponseBody>

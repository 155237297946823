import validate from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/validate.js";
import is_45desktop_45global from "/opt/render/project/src/apps/ui/core/middleware/is-desktop.global.ts";
import nuxt_45studio_45global from "/opt/render/project/src/apps/ui/core/middleware/nuxt-studio.global.ts";
import manifest_45route_45rule from "/opt/render/project/src/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  is_45desktop_45global,
  nuxt_45studio_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/render/project/src/apps/ui/core/middleware/auth.ts"),
  guest: () => import("/opt/render/project/src/apps/ui/core/middleware/guest.ts")
}
<script setup lang="ts">
const colorMode = useColorMode()
colorMode.preference = 'light'

// visual debugging info
if (localStorage.getItem('debug-ui')) {
  useHead({
    style: [
      {
        innerHTML: `
          [data-ui] {
            outline: 1px dashed red;
            position: relative;
          }
          [data-ui]:after {
            content: attr(data-ui);
            position: absolute;
            top: 0;
            right: 0;
            font-size: 10px;
            background-color: red;
            padding: 2px;
            line-height: 1;
            color: white;
            pointer-events: none;
            z-index: 100;
          }
        `,
      },
    ],
  })
}
</script>

<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<style>
.balanced {
  text-wrap: balance
}

.balanced a {
  @apply text-forgd-primary-300 font-semibold;
}
</style>

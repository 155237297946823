import { z } from 'zod'

const FounderSchema = z.object({
  name: z.string(),
  profile: z.string().nullable(),
})

export const PostRaiseCapitalSchema = z.object({
  projectId: z.string(),
  name: z.string(),
  ticker: z.string().nullable(),
  website: z.string(),
  typeOfRaise: z.enum([
    'token-sale',
    'equity',
    'debt',
    'combination',
    'other',
    'uncertain',
  ]),
  desiredFundingAmount: z.number(),
  amountRaisedToDate: z.number(),
  useOfFunds: z.string(),
  existingInvestors: z.array(z.string()).optional(),
  founders: z.array(FounderSchema),
  whitePaper: z.string().optional(),
  currentCash: z.number().optional(),
  monthlyBurn: z.number().optional(),
  country: z.string(),
  businessDescription: z.string(),
  productsDescription: z.string(),
  additionalInformation: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
})

export const PostRaiseCapitalResult = z.object({
  id: z.string(),
  projectId: z.string(),
  name: z.string(),
  ticker: z.string().nullable(),
  website: z.string(),
  typeOfRaise: z.string(),
  desiredFundingAmount: z.number(),
  amountRaisedToDate: z.number(),
  useOfFunds: z.string(),
  existingInvestors: z.array(z.string()),
  founders: z.array(FounderSchema),
  whitePaper: z.string(),
  currentCash: z.number(),
  monthlyBurn: z.number(),
  country: z.string(),
  businessDescription: z.string(),
  productsDescription: z.string(),
  additionalInformation: z.string(),
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullable(),
})

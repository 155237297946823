import { initContract } from '@ts-rest/core'
import {
  LoadTDTemplate,
  ModelingPreview,
  ModelingPreviewResponse,
  PostModelingBody,
  PostTokenProfileBody,
  PutAdjustingBody,
  ReferenceProjects,
  Section,
  SubSection,
  TokenDesigner,
  Dashboard,
  ReferenceTokenDesigners,
} from '../types/token-designer'
import { z } from 'zod'

const c = initContract()

export const tokenDesignerContract = c.router({
  getProjectReferences: {
    method: 'GET',
    path: `token-designer/references`,
    responses: {
      200: ReferenceProjects,
    },
    summary: 'Get project references',
  },
  getProjectTemplates: {
    method: 'GET',
    path: `token-designer/templates`,
    responses: {
      200: ReferenceTokenDesigners,
    },
    summary: 'Get project templates',
  },
  getTD: {
    method: 'GET',
    path: `token-designer`,
    query: z.object({
      projectId: z.string({ required_error: 'projectId is required' }).uuid(),
    }),
    responses: {
      200: TokenDesigner,
    },
    summary: 'Get token designer for project',
  },
  loadTD: {
    method: 'PUT',
    path: `token-designer`,
    query: z.object({
      projectId: z.string({ required_error: 'projectId is required' }).uuid(),
    }),
    body: LoadTDTemplate,
    responses: {
      200: TokenDesigner,
    },
    summary: 'Upload a template into token designer for project',
  },
  getDashboard: {
    method: 'GET',
    path: 'token-designer/dashboard',
    query: z.object({
      projectId: z.string({ required_error: 'projectId is required' }).uuid(),
    }),
    responses: {
      200: Dashboard,
    },
    summary: 'Get dashboard info for UI',
  },
  // Token Profile CRUD
  getTDTokenProfile: {
    method: 'GET',
    path: `token-designer/token-profile`,
    query: z.object({
      projectId: z.string({ required_error: 'projectId is required' }).uuid(),
    }),
    responses: {
      200: Section,
    },
    summary: 'Get token designer token profile for project',
  },
  putTDTokenProfile: {
    method: 'PUT',
    path: `token-designer/token-profile`,
    query: z.object({
      projectId: z.string({ required_error: 'projectId is required' }).uuid(),
      complete: z
        .string()
        .optional()
        .default('false')
        .transform((value) => value === 'true'),
    }),
    body: PostTokenProfileBody,
    responses: {
      201: TokenDesigner,
    },
    summary: 'Update profile values of token designer for project',
  },
  // Modeling CRUD
  getTDModeling: {
    method: 'GET',
    path: `token-designer/modeling`,
    query: z.object({
      projectId: z.string({ required_error: 'projectId is required' }).uuid(),
      preview: z
        .string()
        .optional()
        .default('true')
        .transform((value) => value === 'true'),
    }),
    responses: {
      200: z.object({
        section: Section,
        preview: ModelingPreviewResponse.nullable(),
      }),
    },
    summary: 'Get modeling of token designer modeling for project',
  },
  compareTD: {
    method: 'GET',
    path: `token-designer/compare`,
    query: z.object({
      projectId: z.string({ required_error: 'projectId is required' }).uuid(),
      slug: z.string(),
      name: z.string(),
    }),
    responses: {
      200: z.array(
        z.object({
          projectId: z.string().nullable(),
          subSection: SubSection.nullable(),
          preview: ModelingPreview.nullable(),
        }),
      ),
    },
    summary: 'Compare token designer for project with reference project',
  },
  putTDModeling: {
    method: 'PUT',
    path: `token-designer/modeling`,
    query: z.object({
      projectId: z.string({ required_error: 'projectId is required' }).uuid(),
      preview: z
        .string()
        .optional()
        .default('true')
        .transform((value) => value === 'true'),
      complete: z
        .string()
        .optional()
        .default('false')
        .transform((value) => value === 'true'),
    }),
    body: PostModelingBody,
    responses: {
      201: z.object({
        tokenDesigner: TokenDesigner,
        preview: ModelingPreviewResponse.nullable(),
      }),
    },
    summary: 'Update modeling values of token designer for project',
  },
  getAdjusting: {
    method: 'GET',
    path: 'token-designer/adjusting',
    query: z.object({
      projectId: z.string(),
    }),
    responses: {
      201: z.object({
        section: Section,
      }),
    },
    summary: 'Get Adjusting data',
  },
  putAdjusting: {
    method: 'PUT',
    path: 'token-designer/adjusting',
    query: z.object({
      projectId: z.string({ required_error: 'projectId is required' }).uuid(),
      complete: z
        .string()
        .optional()
        .default('false')
        .transform((value) => value === 'true'),
    }),
    body: PutAdjustingBody,
    responses: {
      201: z.object({
        tokenDesigner: TokenDesigner,
      }),
    },
    summary: 'Update adjusting section',
  },
})

export type TokenDesignerContractType = typeof tokenDesignerContract

import mitt from 'mitt'
import chartPlugin from './acheron/chartPlugin'
import helpersPlugin from './acheron/helpersPlugin'

export default defineNuxtPlugin((nuxtApp) => {
  // TODO replace mitt(), this should a Vue native feature or Nuxt hooks
  window.emitter = mitt()

  chartPlugin(nuxtApp.vueApp)
  helpersPlugin(nuxtApp.vueApp)
})

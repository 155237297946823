import { parseISO, isValid } from 'date-fns'
import { z } from 'zod'

export const customDateSchema = z
  .string()
  .refine(
    (value) => {
      const parsedDate = parseISO(value)
      return isValid(parsedDate)
    },
    {
      message: 'Invalid date format',
    },
  )
  .transform((value) => new Date(value))

import { defu } from 'defu'

const colors = [
  '#BDA46C',
  '#409090',
  '#E8BFD8',
  '#CD5F5F',
  '#92C2D1',
  '#2476A4',
  '#DF9E52',
  '#C0D2A3',
  '#66A680',
  '#5D6DA5',
]

const fonts = [
  'Inter',
  'ui-sans-serif',
  'system-ui',
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Helvetica Neue',
  'Arial',
]

const stockColors = {
  green: '#4ca49c',
  red: '#e46454',
  dark: '#070932',
  blue: '#2334D8',
  light: 'white',
}

function legend(options = {}) {
  return defu(
    options,
    {
      enabled: true,
      itemStyle: {
        fontSize: '11px',
        fontWeight: 500,
        color: '#484848',
      },
      layout: 'horizontal',
      symbolHeight: 8,
      symbolWidth: 8,
      align: 'left',
      maxHeight: 60,
      navigation: {
        arrowSize: 6,
      },
      padding: 0,
    },
  )
}

function yAxis(yAxisOptions) {
  const yAxisDefault = {
    gridLineColor: '#e5e5e5',
    gridLineDashStyle: 'dash',
    showFirstLabel: false,
    title: false,
    labels: {
      style: {
        fontSize: '10px',
        color: '#484848',
      },
      y: 3,
    },
  }

  if (yAxisOptions.length === 0 || !yAxisOptions) {
    return yAxisDefault
  }

  if (yAxisOptions?.length > 0 && Array.isArray(yAxisOptions)) {
    Object.entries(yAxisOptions).forEach(([key, yAxisOption]) => {
      yAxisOptions[key] = defu(yAxisOption, yAxisDefault)
    })
  }

  if (typeof yAxisOptions == 'object' && Array.isArray(!yAxisOptions)) {
    yAxisOptions = defu(yAxisOptions, yAxisDefault)
  }

  return yAxisOptions
}
function xAxis(options) {
  return defu(
    options,
    {
      title: false,
      lineColor: '#B0B0B0',
      tickColor: '#B0B0B0',
      tickLength: 5,
      useHtml: true,
      labels: {
        style: {
          fontSize: '10px',
          color: '#484848',
        },
        formatter() {
          const label = this.axis.defaultLabelFormatter.call(this)

          const dateParts = label.split(' ').filter(part => part !== '')

          let tickLabel = `<div>${dateParts[0]}</div>`

          if (dateParts[1]) {
            tickLabel += `</br><div class="">${dateParts[1]}</div>`
          }

          return tickLabel
        },
      },
      type: 'datetime',
    },
  )
}

function chart(options = {}) {
  return defu(
    options,
    {
      style: {
        fontFamily: fonts,
      },
      spacing: [10, 4, 4, 4],
    },
  )
}

function title(options) {
  if (!options.text) {
    return undefined
  }

  return {
    text: options.text,
    align: 'center',
    style: {
      fontWeight: 400,
      color: '#8D8D8D',
      fontSize: '10px',
      backgroundColor: '#fff',
      borderRadius: '3px',
      padding: '0 4px',
    },
    floating: true,
    useHTML: true,
    y: 3,
  }
}

const defaultSettings = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
}

function plotOptions(options = {}) {
  const defaultOptions = {
    area: {
      marker: {
        enabled: false,
        symbol: 'circle',
        radius: 2,
      },
      stacking: 'normal',
      lineWidth: 1.5,
    },
    spline: {
      marker: {
        enabled: false,
        symbol: 'circle',
        radius: 2,
      },
      lineWidth: 1.5,
    },
    column: {
      type: 'column',
      stacking: 'normal',
      dataLabels: {
        enabled: false,
      },
    },
    candlestick: {
      upColor: '#2869F0',
      color: 'red',
    },
    pie: {},
  }

  Object.entries(defaultOptions).forEach(([key, defaultOption]) => {
    if (options[key]) {
      defaultOption = defu(options[key], defaultOption)
    }
  })

  return defaultOptions
}

function tooltips(options) {
  return {
    forgd: {
      useHTML: true,
      shadow: false,
      backgroundColor: 'transparent',
      padding: 0,

      formatter() {
        const date = new Date(this.points[0].x).toLocaleString('en-US', {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          hour: '2-digit',
          hour12: false,
          minute: '2-digit',
        })

        let totalAmount = 0

        let tooltip
                  = '<div class="flex flex-col p-3 space-y-3 bg-white border border-gray-200 rounded-md shadow-sm">'
        tooltip += `<span class="text-xs font-medium">${date}</span>`

        this.points.forEach((point) => {
          totalAmount += point.y

          let amount = point.y

          if (options.valuePrefix == '$') {
            amount = new Intl.NumberFormat('en-US', {
              maximumFractionDigits: 0,
            }).format(point.y)
          }

          tooltip += '<div class="flex items-start space-x-1.5">'
          tooltip += `<div class="w-1 h-3 mt-0.5 rounded-full bg-cyan-400" style="background-color: ${point.color}"></div>`
          tooltip += '<div class="flex flex-col space-y-0.5">'
          tooltip += `<div class="text-xs">${point.series.name}</div>`
          tooltip += `<div class="text-sm font-medium tabular-nums">${
                      options.valuePrefix ?? ''
                  }${amount}${options.valueSuffix ?? ''}</div>`
          tooltip += '</div>'
          tooltip += '</div>'
        })

        if (this.points.length > 1 && (options.showTotal ?? true)) {
          tooltip += '<div class="flex items-start space-x-1.5">'
          tooltip += '<div class="w-1 h-3 mt-0.5 rounded-full"></div>'
          tooltip += '<div class="flex flex-col">'
          tooltip += '<div class="flex flex-col">Total</div>'
          tooltip += `<div class="text-sm font-medium tabular-nums">${new Intl.NumberFormat(
                      'en-US',
                      {
                          style: 'currency',
                          currency: 'USD',
                          maximumFractionDigits: 0,
                      },
                  ).format(totalAmount)}</div>`
          tooltip += '</div>'
          tooltip += '</div>'
        }

        return tooltip
      },
    },
    dollarPercentage: {
      shared: true,
      formatter() {
        const formatted_points = []
        this.points.forEach((point, index) => {
          point.percentage = point.percentage ?? 0

          formatted_points.push(
            `<br/><tspan style="fill:${
                           point.color
                           }">●</tspan> ${
                           point.series.name
                           }: <strong>${
                           new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            maximumFractionDigits: 0,
                          }).format(point.y)
                           }</strong> (${
                           point.percentage.toFixed(2)
                           }%)`,
          )
        })
        formatted_points.unshift(
          `${this.points[0].x
                       } - Total: ${
                       new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        maximumFractionDigits: 0,
                      }).format(this.points[0].total)}`,
        )
        return formatted_points
      },
    },
    dollars: {
      formatter() {
        const date = new Date(this.points[0].x)
          .toLocaleString('en-US', {
            year: '2-digit', // numeric, 2-digit
            month: 'short', // numeric, 2-digit, long, short, narrow
          })
          .replace(' ', ' \'')

        return (
          `<div class='inline-block' style='font-size:10px; padding-bottom 14px;'>${
           date
                   }</div></br>`
                   + `<tspan style="fill:${
                   this.points[0].color
                   }">●</tspan> `
                   + `MRR: <strong>${
                   new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    maximumFractionDigits: 0,
                  }).format(this.points[0].y)}`
        )
      },
    },
  }
}

function tooltip(options = {}) {
  let tooltipSettings = {
    style: {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '16px',
    },
    shared: true,
  }

  if (options.formatter && tooltips()[options.formatter.name]) {
    tooltipSettings = defu(
      tooltips(options.formatter)[options.formatter.name],
      tooltipSettings,
    )
  }

  return tooltipSettings
}

function fillColor(data, index) {
  if (data.filter(dataSet => dataSet.type === 'area').length === 1) {
    return {
      linearGradient: [0, 0, 0, 300],
      stops: [
        [0, Highcharts.color(colors[index]).setOpacity(0.2).get('rgba')],
        [1, Highcharts.color(colors[index]).setOpacity(0).get('rgba')],
      ],
    }
  }

  return undefined
}

function dataStyling(data) {
  const styledData = []

  data.forEach((dataSet, index) => {
    if (dataSet.type == 'area') {
      styledData.push(
        defu({
          color: colors[index],
          fillColor: fillColor(data, index),
        }, dataSet),
      )
    }
    else {
      styledData.push(dataSet)
    }
  })

  return styledData
}

const chartSettings = {
  colors,
  fonts,
  stockColors,
  chart,
  title,
  legend,
  yAxis,
  xAxis,
  plotOptions,
  tooltip,
  fillColor,
  dataStyling,
  defaultSettings,
}

export default function (app) {
  app.config.globalProperties.$chartSettings = chartSettings
}

export { chartSettings }

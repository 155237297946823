import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import { PostRaiseCapitalSchema, PostRaiseCapitalResult } from '../types'

const c = initContract()

export const raiseCapitalContract = c.router({
  postRaiseCapital: {
    method: 'POST',
    path: `raise-capital`,
    responses: {
      201: PostRaiseCapitalResult,
    },
    body: PostRaiseCapitalSchema,
    summary: 'Create raise capital request',
  },
  getRaiseCapitalByProjectId: {
    method: 'GET',
    path: `raise-capital`,
    responses: {
      200: PostRaiseCapitalResult,
    },
    query: z.object({
      projectId: z.string({ required_error: 'projectId is required' }).uuid(),
    }),
    summary: 'Get raise capital by project id',
  },
})

export type RaiseCapitalContractType = typeof raiseCapitalContract

import { z } from 'zod'

// Exchange schema
export const ExchangeResult = z.object({
  id: z.string(),
  name: z.string(),
  image: z.string().nullable(),
  dex: z.boolean(),
  volumeSupported: z.boolean(),
  coingeckoId: z.string().nullable(),
})
export const ExchangesArrayTemplate = z.array(ExchangeResult)
export type ExchangeResultType = z.infer<typeof ExchangeResult>

export const ExchangeAccountResult = z.object({
  amm2Id: z.string().uuid(),
  accountId: z.number(),
  accountType: z.string(),
  acheronId: z.string(),
  name: z.string(),
  base: z.string(),
  quote: z.string(),
  baseId: z.string(),
  quoteId: z.string(),
  baseImage: z.string().nullable(),
  quoteImage: z.string().nullable(),
  exchangeImage: z.string().nullable(),
  config: z.array(z.string()),
  deletedAt: z.union([z.date().nullable(), z.null()]),
  id: z.string().uuid(),
  createdAt: z.date(),
  updatedAt: z.date(),
  __entity: z.string().optional(),
})

// AMM2 schema
export const PostAmm2Schema = z.object({
  projectId: z.string().uuid(),
  strategy: z
    .enum(['delta-neutral', 'tunnel', 'migrate', 'arbitrage'])
    .default('delta-neutral'),
  tradingStyle: z
    .enum(['conservative', 'moderate', 'aggressive'])
    .default('conservative'),
  depositAmount: z.number().positive(),
  baseSplitPercentage: z.number().positive(),
  quoteSplitPercentage: z.number(),
})

const strategyId = z.object({
  strategyId: z.number(),
  amm2Id: z.string().uuid(),
  id: z.string().uuid(),
  deletedAt: z.union([z.date().nullable(), z.null()]),
  createdAt: z.date(),
  updatedAt: z.date(),
  __entity: z.string().optional(),
})

export const PostAmm2Result = z.object({
  id: z.string(),
  projectId: z.string(),
  strategy: z.string(),
  tradingStyle: z.string(),
  depositAmount: z.number(),
  baseSplitPercentage: z.number(),
  quoteSplitPercentage: z.number(),
  deployDate: z.date().optional().nullable(),
  status: z.string({
    description:
      'in-review | active | delayed | scheduled | deactivated | draft | rejected',
  }),
  isDeployed: z.boolean(),
  exchanges: z.array(ExchangeAccountResult.optional()),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().optional(),
  strategyId: strategyId.nullable(),
})

export type PostAmm2SchemaType = z.infer<typeof PostAmm2Schema>
export type PostAmm2ResultType = z.infer<typeof PostAmm2Result>

export const PostAmm2Lead = z.object({
  hasLiquidityPartner: z.boolean(),
  isListed: z.boolean(),
  name: z.string().trim().min(1),
  firstName: z.string().trim().min(1),
  lastName: z.string().trim().min(1),
  projectId: z.string().uuid(),
  siteUrl: z.string().trim().optional().nullable(),
  tgeDate: z.string().datetime().optional().nullable(),
  ticker: z
    .string()
    .trim()
    .min(1)
    .transform((val) => val.toUpperCase()),
})

export const GetAmm2LeadResult = PostAmm2Lead.extend({
  id: z.string().uuid(),
})

// Exchange Account Schema
export const PostAmm2ExchangeAccountSchema = z.object({
  amm2Id: z.string().uuid(),
  id: z.string(),
  name: z.string(),
  base: z.string(),
  quote: z.string(),
  baseId: z.string(),
  quoteId: z.string(),
  accountType: z.enum(['primary', 'secondary']).default('primary'),
  config: z.record(z.string()),
})

export const PostAmm2ExchangeAccountResult = z.object({
  amm2Id: z.string().uuid(),
  accountId: z.number(),
  accountType: z.string(),
  acheronId: z.string(),
  name: z.string(),
  base: z.string(),
  quote: z.string(),
  baseId: z.string(),
  quoteId: z.string(),
  baseImage: z.string().nullable(),
  quoteImage: z.string().nullable(),
  exchangeImage: z.string().nullable(),
  config: z.array(z.string()),
  deletedAt: z.union([z.date().nullable(), z.null()]),
  id: z.string().uuid(),
  createdAt: z.date(),
  updatedAt: z.date(),
  __entity: z.string().optional(),
})

export type PostAmm2ExchangeAccountSchemaType = z.infer<
  typeof PostAmm2ExchangeAccountSchema
>

// Deploy Amm2 Strategy
export const DeployAmm2StrategySchema = z.object({
  amm2Id: z.string().uuid(),
  deployDate: z.string(),
})

export type DeployAmm2StrategySchemaType = z.infer<
  typeof DeployAmm2StrategySchema
>

// Pairs Schema
export const PairsResult = z.object({
  base: z.string(),
  quote: z.string(),
  baseId: z.string(),
  quoteId: z.string().nullable(),
  baseImage: z.string().nullable(),
  quoteImage: z.string().nullable(),
})

export type PairsResultType = z.infer<typeof PairsResult>

// Exhange Congif Schema
export const ExchangeConfigSchemaResult = z.object({
  id: z.string(),
  name: z.string(),
  config: z.array(z.string()),
})

export type ExchangeConfigSchemaResultType = z.infer<
  typeof ExchangeConfigSchemaResult
>

// Exchange Verify Config Schema
export const postAmm2ExchangeVerifyConfig = z.object({
  id: z.string(),
  base: z.string(),
  quote: z.string(),
  config: z.record(z.string()),
})

export type postAmm2ExchangeVerifyConfigType = z.infer<
  typeof postAmm2ExchangeVerifyConfig
>

// Split Estimate Schema
export const postAmm2ExchangeSplitEstimate = z.object({
  primary: z.object({
    exchangeId: z.string(),
    base: z.string(),
    quote: z.string(),
    baseId: z.string(),
    quoteId: z.string(),
  }),
  secondary: z
    .object({
      exchangeId: z.string(),
      base: z.string(),
      quote: z.string(),
      baseId: z.string(),
      quoteId: z.string(),
    })
    .optional(),
  depositAmount: z.number(),
  baseSplitPercentage: z.number(),
  quoteSplitPercentage: z.number(),
})

const ExchangeSplit = z.object({
  exchangeId: z.string(),
  base: z.string(),
  quote: z.string(),
  baseId: z.string(),
  quoteId: z.string(),
  baseUSD: z.number(),
  quoteUSD: z.number(),
  baseAmount: z.number(),
  quoteAmount: z.number(),
})

export const ExchangeSplitResult = z.object({
  primary: ExchangeSplit,
  secondary: ExchangeSplit.optional(), // secondary is optional
})

export type ExchangeSplitResultType = z.infer<typeof ExchangeSplitResult>

export type postAmm2ExchangeSplitEstimateType = z.infer<
  typeof postAmm2ExchangeSplitEstimate
>

const BalanceSchema = z.object({
  total: z.number(),
  price: z.number(),
})

export const postAmm2ExchangeVerifyConfigResult = z.object({
  valid: z.boolean(),
  balances: z.record(BalanceSchema),
})

export type postAmm2ExchangeVerifyConfigResultType = z.infer<
  typeof postAmm2ExchangeVerifyConfigResult
>

const DataPoint = z.tuple([z.number(), z.number()])
const Summary = z.object({
  amm2: z.object({
    current: z.number(),
    previous: z.number(),
    percentChange: z.number(),
  }),
  market: z.object({
    current: z.number(),
    previous: z.number(),
    percentChange: z.number(),
  }),
})

export const AcheronGetVolumeResponseMarket = z.object({
  timestamp: z.number(),
  interval: z.string(),
  open: z.number(),
  low: z.number(),
  high: z.number(),
  close: z.number(),
  volume: z.number(),
})

export const GetVolumeVsMarkets = z.object({
  amm2: z.array(DataPoint),
  market: z.array(DataPoint),
  summary: Summary,
})
export type GetVolumeVsMarketsType = z.infer<typeof GetVolumeVsMarkets>

export const AcheronGetLiquidityResponseMarket = z.object({
  timestamp: z.number(),
  last_price: z.number(),
  last_price_usd: z.number(),
  '24h_volume': z.number(),
  bid_ask_spread_percentage: z.number(),
  cost_to_move_2p_up_usd: z.number(),
  cost_to_move_2p_down_usd: z.number(),
})

export const GetLiquidityMarket = z.object({
  amm2: z.array(DataPoint),
  market: z.array(DataPoint),
  summary: Summary,
})

export const GetLiquidityMarkets = z.object({
  amm2: z.array(DataPoint),
  market: z.array(DataPoint),
  summary: Summary,
})

export const AcheronGetTradesSchema = z.object({
  id: z.number(),
  symbol: z.string(),
  price: z.number(),
  volume: z.number(),
  value_in_quote: z.number(),
  value_in_usd: z.number(),
  side: z.string(),
  timestamp: z.string(), // If the timestamp follows a specific format, you might want to use more specific validation.
  type: z.string(),
})

export type AcheronGetTradesResult = z.infer<typeof AcheronGetTradesSchema>

const OrderSchema = z.object({
  price: z.number(),
  volume: z.number(),
})

export const OrderBookResponseSchema = z.object({
  timestamp: z.number(),
  asks: z.array(OrderSchema),
  bids: z.array(OrderSchema),
})

export type OrderBookResponseType = z.infer<typeof OrderBookResponseSchema>

export const ExchangeSummaryResult = z.object({
  id: z.string(),
  name: z.string(),
  exchangeName: z.string(),
  exchangeImage: z.string(),
  base: z.string(),
  quote: z.string(),
  baseId: z.string(),
  quoteId: z.string(),
  baseImage: z.string().nullable(),
  quoteImage: z.string().nullable(),
  totalAmm2Volume: z.number(),
  totalAmm2Liquidity: z.number(),
})

export type ExchangeSummaryResultType = z.infer<typeof ExchangeSummaryResult>

export const Amm2SummaryResult = z.object({
  id: z.string(),
  strategy: z.string(),
  strategyName: z.string(),
  tradingStyle: z.string(),
  tradingStyleName: z.string(),
  isDeployed: z.boolean(),
  deployDate: z.date().nullable(),
  status: z.string({
    description:
      'in-review | active | delayed | scheduled | deactivated | draft | rejected',
  }),
  exchanges: z.array(ExchangeSummaryResult),
})

export type Amm2SummaryResultType = z.infer<typeof Amm2SummaryResult>

export const DeactivateStrategySchema = z.object({
  status: z.enum(['deactivate']).default('deactivate'),
})

export const MarketConditionsResponse = z.object({
  volume: z.number(),
  averageSpread: z.number(),
  averageSpreadVolume: z.number(),
})

const changes = z.object({
  label: z.string(),
  old: z.string(),
  new: z.string(),
})

const revisions = z.object({
  version: z.number(),
  date: z.string(),
  uptime: z.string(),
  volume: z.number(),
  changes: z.array(changes),
  user: z.string(),
})

export const AcheronGetStrategySimpleResponseType = z.object({
  id: z.number(),
  name: z.string(),
  class: z.string(),
  status: z.string(),
  config: z.object({
    adjustAsk: z.number(),
    askPrice: z.string(),
    askVolume: z.number(),
    base: z.string(),
    margin: z.number(),
    maxTotalBidAmount: z.number(),
    minPrice: z.number(),
    percentBid: z.number(),
    quote: z.string(),
    randomness: z.number(),
  }),
  accounts: z.object({
    main: z.number(),
    secondary: z.number().nullable(),
  }),
  revisions: z.array(revisions),
})

const PlatformSchema = z.object({
  name: z.string(),
  accountId: z.number(),
  image: z.string(),
  isActive: z.boolean(),
  balance: z.object({
    total: z.number(),
    available: z.number(),
    price: z.number(),
  }),
})

const AssetSchema = z.object({
  asset: z.string(),
  image: z.string(),
  platforms: z.array(PlatformSchema),
})

const SummaryPlatformSchema = z.object({
  name: z.string(),
  image: z.string(),
  balanceToken: z.number(),
  balanceUSD: z.number(),
  activeStrategies: z.number(),
  percentDistribution: z.number(),
})

export const SummarySchema = z.object({
  asset: z.string(),
  image: z.string(),
  totalBalanceUSD: z.number(),
  totalBalanceToken: z.number(),
  percentDistribution: z.number(),
  activeStrategies: z.number(),
  platforms: z.array(SummaryPlatformSchema),
})

export type SummaryType = z.infer<typeof SummarySchema>
export type AssetSchema = z.infer<typeof AssetSchema>

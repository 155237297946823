import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import {
  AcheronGetStrategySimpleResponseType,
  AcheronGetTradesSchema,
  Amm2SummaryResult,
  DeactivateStrategySchema,
  DeployAmm2StrategySchema,
  ExchangeConfigSchemaResult,
  ExchangeResult,
  ExchangeSplitResult,
  GetAmm2LeadResult,
  GetLiquidityMarket,
  GetLiquidityMarkets,
  GetVolumeVsMarkets,
  MarketConditionsResponse,
  OrderBookResponseSchema,
  PairsResult,
  PostAmm2ExchangeAccountResult,
  PostAmm2ExchangeAccountSchema,
  postAmm2ExchangeSplitEstimate,
  postAmm2ExchangeVerifyConfig,
  postAmm2ExchangeVerifyConfigResult,
  PostAmm2Lead,
  PostAmm2Result,
  PostAmm2Schema,
  SummarySchema,
} from '../types'

const c = initContract()

export const amm2Contract = c.router({
  getAmm2Exchanges: {
    method: 'GET',
    path: `amm2/exchanges`,
    responses: {
      200: z.array(ExchangeResult),
    },
    summary: 'Get amm2 exchanges',
  },
  getExchangesFilteredByToken: {
    method: 'GET',
    path: `amm2/exchanges/filtered-by-project-token`,
    responses: {
      200: z.array(ExchangeResult),
    },
    query: z.object({
      projectId: z.string({ required_error: 'projectId is required' }).uuid(),
    }),
    summary: 'Get amm2 exchanges filtered by if they support token trading',
  },
  getAmm2Pairs: {
    method: 'GET',
    path: `amm2/pairs`,
    responses: {
      200: z.array(PairsResult),
    },
    query: z.object({
      exchange: z.string({ required_error: 'exchange name is required' }),
      projectId: z.string({ required_error: 'projectId is required' }).uuid(),
    }),
    summary: 'Get amm2 trading pairs for token and exchange',
  },
  getAllPairsForSupportedExchanges: {
    method: 'GET',
    path: `amm2/pairs/all`,
    responses: {
      200: z.array(PairsResult),
    },
    summary: 'Get all amm2 trading pairs for supported exchanges',
  },
  postAmm2GetSplitEstimate: {
    method: 'POST',
    path: `amm2/split-estimate`,
    responses: {
      200: ExchangeSplitResult,
    },
    body: postAmm2ExchangeSplitEstimate,
    summary: 'Get amm2 split estimate',
  },
  postAmm2: {
    method: 'POST',
    path: `amm2`,
    responses: {
      201: PostAmm2Result,
    },
    body: PostAmm2Schema,
    summary: 'Create amm2',
  },
  putAmm2: {
    method: 'PUT',
    path: `amm2/:id`,
    responses: {
      201: PostAmm2Result,
    },
    pathParams: z.object({
      id: z.string().uuid(),
    }),
    body: PostAmm2Schema,
    summary: 'Update amm2',
  },
  getAmm2: {
    method: 'GET',
    path: `amm2`,
    responses: {
      200: z.array(PostAmm2Result),
    },
    query: z.object({
      projectId: z.string({ required_error: 'projectId is required' }).uuid(),
    }),
    summary: 'Get amm2',
  },
  getAmm2byId: {
    method: 'GET',
    path: `amm2/:id/strategy`,
    responses: {
      200: PostAmm2Result,
    },
    pathParams: z.object({
      id: z.string({ required_error: 'amm2 id is required' }).uuid(),
    }),
    summary: 'Get amm2 by id',
  },
  getAmm2Summary: {
    method: 'GET',
    path: `amm2/summary`,
    responses: {
      200: z.array(Amm2SummaryResult),
    },
    query: z.object({
      projectId: z.string({ required_error: 'projectId is required' }).uuid(),
    }),
    summary: 'Get amm2 summary',
  },
  getAmm2ExchangeConfig: {
    method: 'GET',
    path: `amm2/exchange-config`,
    responses: {
      200: ExchangeConfigSchemaResult,
    },
    query: z.object({
      exchange: z.string({ required_error: 'exchange name is required' }),
    }),
    summary: 'Get amm2 exchange config',
  },
  postAmm2ExchangeVerifyConfig: {
    method: 'POST',
    path: `amm2/exchange-config/verify`,
    responses: {
      200: postAmm2ExchangeVerifyConfigResult,
    },
    body: postAmm2ExchangeVerifyConfig,
    summary: 'Verify amm2 exchange config',
  },
  postAmm2ExchangeAccount: {
    method: 'POST',
    path: `amm2/exchange-account`,
    responses: {
      201: PostAmm2ExchangeAccountResult,
    },
    body: PostAmm2ExchangeAccountSchema,
    summary: 'Verify amm2 exchange account',
  },
  putAmm2ExchangeAccount: {
    method: 'PUT',
    path: `amm2/exchange-account/:id`,
    responses: {
      201: PostAmm2ExchangeAccountResult,
    },
    pathParams: z.object({
      id: z.string().uuid(),
    }),
    body: PostAmm2ExchangeAccountSchema,
    summary: 'Update amm2 exchange account',
  },
  deployAmm2Strategy: {
    method: 'POST',
    path: `amm2/deploy-strategy`,
    responses: {
      201: PostAmm2Result,
    },
    body: DeployAmm2StrategySchema,
    summary: 'Deploy amm2 strategy',
  },
  deactivateStrategy: {
    method: 'PATCH',
    path: `amm2/:id/deactivate-strategy`,
    responses: {
      201: PostAmm2Result,
    },
    pathParams: z.object({
      id: z.string().uuid(),
    }),
    body: DeactivateStrategySchema,
    summary: 'Deactivate amm2 strategy',
  },
  getTotalVolumeForStrategies: {
    method: 'GET',
    path: `amm2/total-volume`,
    responses: {
      200: z.object({
        totalVolume: z.number(),
      }),
    },
    query: z.object({
      projectId: z.string().uuid(),
    }),
    summary:
      'Get amm2 total volume for all amm2. This gets all amm2 strategies for a project and sums all trades volume across all exchange for each strategy for 24 hr period',
  },
  getTotalVolumeForStrategy: {
    method: 'GET',
    path: `amm2/:id/total-volume`,
    responses: {
      200: z.object({
        totalVolume: z.number(),
      }),
    },
    pathParams: z.object({
      id: z.string().uuid(),
    }),
    summary:
      'Get amm2 total volume for one amm2. This gets a single amm2 strategy and sums all trades volume across all exchange for that strategy for 24 hr period',
  },
  getTotalLiquidityForStrategy: {
    method: 'GET',
    path: `amm2/:id/total-liquidity`,
    responses: {
      200: z.object({
        liquidityPercentage: z.number(),
        averageSpread: z.number(),
      }),
    },
    pathParams: z.object({
      id: z.string().uuid(),
    }),
    query: z.object({
      exchangeId: z.string().uuid(),
    }),
    summary:
      'Get amm2 total liquidity for one amm2. This gets a single amm2 strategies for a project and returns the average +/- 2% spread and the average spread for 24 hr period',
  },
  getTotalLiquidityForStrategies: {
    method: 'GET',
    path: `amm2/total-liquidity`,
    responses: {
      200: z.object({
        liquidityPercentage: z.number(),
        averageSpread: z.number(),
      }),
    },
    query: z.object({
      projectId: z.string().uuid(),
    }),
    summary:
      'Get amm2 total liquidity for all amm2. This gets all amm2 strategies for a project and returns the average +/- 2% spread and the average spread for 24 hr period',
  },
  getTotalLiquiditiyVsMarkets: {
    method: 'GET',
    path: `amm2/liquiditiy-vs-markets`,
    responses: {
      200: GetLiquidityMarkets,
    },
    query: z.object({
      projectId: z.string().uuid(),
      days: z.enum(['1', '7', '30']),
      type: z.enum(['asks', 'bids', 'all']),
    }),
    summary: 'Get amm2 total liquidity for all exchanges in amm2',
  },
  getTotalLiquiditiyVsMarket: {
    method: 'GET',
    path: `amm2/liquiditiy-vs-market`,
    responses: {
      200: GetLiquidityMarket,
    },
    query: z.object({
      exchangeId: z.string().uuid(),
      days: z.enum(['1', '7', '30']),
      type: z.enum(['asks', 'bids', 'all']),
    }),
    summary: 'Get amm2 total liquidity for a single exchange in amm2',
  },
  getTotalVolumeVsMarkets: {
    method: 'GET',
    path: `amm2/volume-vs-markets`,
    responses: {
      200: GetVolumeVsMarkets,
    },
    query: z.object({
      projectId: z.string().uuid(),
      days: z.enum(['1', '7', '30']),
      side: z.enum(['buy', 'sell', 'all']),
    }),
    summary: 'Get amm2 total volume for all exchanges in all amm2',
  },
  getTotalVolumeVsMarket: {
    method: 'GET',
    path: `amm2/volume-vs-market`,
    responses: {
      200: GetVolumeVsMarkets,
    },
    query: z.object({
      exchangeId: z.string().uuid(),
      days: z.enum(['1', '7', '30']),
      side: z.enum(['buy', 'sell', 'all']),
    }),
    summary: 'Get amm2 total volume for a single exchange in all amm2',
  },
  getTradesForMarket: {
    method: 'GET',
    path: `amm2/:id/trades`,
    responses: {
      200: z.array(AcheronGetTradesSchema),
    },
    pathParams: z.object({
      id: z.string().uuid(),
    }),
    query: z.object({
      exchangeId: z.string().uuid(),
    }),
    summary: 'Get trades for a single amm2',
  },
  getOrderBookForMarket: {
    method: 'GET',
    path: `amm2/:id/order-book`,
    responses: {
      200: OrderBookResponseSchema,
    },
    pathParams: z.object({
      id: z.string().uuid(),
    }),
    query: z.object({
      exchangeId: z.string().uuid(),
    }),
    summary: 'Get order book for a single amm2',
  },
  getMarketConditions: {
    method: 'GET',
    path: `amm2/market-conditions`,
    query: z.object({
      exchangeName: z.string().min(1),
      baseId: z.string().min(1),
      quoteId: z.string().min(1),
    }),
    responses: {
      200: MarketConditionsResponse,
    },
    summary: 'Get market conditions for a single market base/quote pair',
  },
  getAdvancedDetails: {
    method: 'GET',
    path: `amm2/:id/advanced-details`,
    responses: {
      200: AcheronGetStrategySimpleResponseType,
    },
    pathParams: z.object({
      id: z.string().uuid(),
    }),
    summary: 'Get advanced details for a single amm2 exchange',
  },
  getAmm2Inventory: {
    method: 'GET',
    path: `amm2/inventory`,
    responses: {
      200: z.array(SummarySchema),
    },
    query: z.object({
      projectId: z.string().uuid(),
    }),
    summary: 'Get amm2 inventory for a single project',
  },

  postAmm2Lead: {
    method: 'POST',
    path: `amm2/lead`,
    responses: {
      204: z.undefined().optional(), // tsRest fix for empty response
    },
    body: PostAmm2Lead,
    summary: 'Create amm2 lead',
  },

  getAmm2Lead: {
    method: 'GET',
    path: `amm2/lead`,
    responses: {
      200: GetAmm2LeadResult,
      204: z.undefined().optional(), // tsRest fix for empty response
    },
    query: z.object({
      projectId: z.string().uuid(),
    }),
    summary: 'Get amm2 lead',
  },
})

export type Amm2ContractType = typeof amm2Contract

import type { Client } from '@bugsnag/js'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import BugsnagPerformance from '@bugsnag/browser-performance'
import { VueRouterRoutingProvider } from '@bugsnag/vue-router-performance'
import Consola from 'consola'
import { defineNuxtPlugin, useRuntimeConfig } from '#imports'

export default defineNuxtPlugin((nuxtApp) => {
  // don't run for histoire
  if (process.env.npm_lifecycle_event?.startsWith('story')) {
    return
  }

  const consola = Consola.create({
    level: import.meta.env.DEV ? 5 : 3,
    defaults: {
      tag: 'Bugsnag',
    },
  })

  let bugsnag: Client = useRequestEvent()?.context.$bugsnag
  if (import.meta.client) {
    const publicConfig = useRuntimeConfig().public

    bugsnag = bugsnag || Bugsnag.start({
      releaseStage: (import.meta.dev ? 'development' : publicConfig.env as string) || 'production',
      metadata: {
        branch: publicConfig.branch,
      },
      logger: consola,
      appType: `Nuxt ${import.meta.client ? 'App' : 'Server'}`,
      appVersion: publicConfig.version,
      apiKey: publicConfig.bugsnagKey,
      plugins: [new BugsnagPluginVue()],
    })
    const router = useRouter()
    BugsnagPerformance.start({
      apiKey: useRuntimeConfig().public.bugsnagKey,
      routingProvider: new VueRouterRoutingProvider(router, '/'),
    })
    nuxtApp.$bugsnag = bugsnag
  }
  if (bugsnag) {
    const plugin = bugsnag.getPlugin('vue')
    if (plugin !== undefined) {
      nuxtApp.vueApp.use(plugin)
    }
  }
})

import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import {
  PutValuationBody,
  Section,
  TokenDesigner,
  ValuationPreviewResponse,
} from '../types'

const c = initContract()

export const tokenDesignerValuationContract = c.router({
  getTDValuation: {
    method: 'GET',
    path: `token-designer/valuation`,
    query: z.object({
      projectId: z.string({ required_error: 'projectId is required' }).uuid(),
      preview: z
        .string()
        .optional()
        .default('true')
        .transform((value) => value === 'true'),
    }),
    responses: {
      200: z.object({
        section: Section,
        preview: ValuationPreviewResponse.nullable(),
      }),
    },
    summary: 'Get token designer valuation & documenting',
  },
  putTDValuation: {
    method: 'PUT',
    path: `token-designer/valuation`,
    query: z.object({
      projectId: z.string({ required_error: 'projectId is required' }).uuid(),
      preview: z
        .string()
        .optional()
        .default('true')
        .transform((value) => value === 'true'),
      complete: z
        .string()
        .optional()
        .default('false')
        .transform((value) => value === 'true'),
    }),
    body: PutValuationBody,
    responses: {
      201: z.object({
        tokenDesigner: TokenDesigner,
        preview: ValuationPreviewResponse.nullable(),
      }),
    },
    summary: 'Update valuation values of token designer for project',
  },
})

export type TokenDesignerValuationContractType =
  typeof tokenDesignerValuationContract

import { formatInTimeZone } from 'date-fns-tz'
import { nextTick } from 'vue'

export function selectText(element) {
  let range
  if (document.selection) {
    // IE
    range = document.body.createTextRange()
    range.moveToElementText(element)
    range.select()
  }
  else if (window.getSelection) {
    range = document.createRange()
    range.selectNode(element)
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(range)
  }
  document.execCommand('copy')
  window.getSelection().removeAllRanges()
}

export function formatDollars(number, signed = false) {
  return Intl.NumberFormat('en-US', {
    signDisplay: signed ? 'always' : 'auto',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: 'USD',
    style: 'currency',
  }).format(number)
}

export function formattedNumber(number, asFloat = true) {
  if (number == 0) {
    return 0
  }

  if (number < 1 && number > -1) {
    return number
  }

  if (number <= 10 && number >= -10) {
    return Intl.NumberFormat('en-US', {
      maximumFractionDigits: asFloat ? 4 : 0,
    }).format(number)
  }

  if (number <= 100 && number >= -100) {
    return Intl.NumberFormat('en-US', {
      maximumFractionDigits: asFloat ? 2 : 0,
    }).format(number)
  }

  if (number <= 1000 && number >= -1000) {
    return Intl.NumberFormat('en-US', {
      maximumFractionDigits: asFloat ? 2 : 0,
    }).format(number)
  }

  if (number <= 10000 && number >= -10000) {
    return Intl.NumberFormat('en-US', {
      maximumFractionDigits: 0,
    }).format(number)
  }

  if (number < 1000000 && number > -1000000) {
    return (
      `${Intl.NumberFormat('en-US', {
        maximumFractionDigits: asFloat ? 1 : 0,
      }).format(number / 1000)}k`
    )
  }

  if (number <= 100000000 && number >= -100000000) {
    return (
      `${Intl.NumberFormat('en-US', {
        maximumFractionDigits: asFloat ? 2 : 0,
      }).format(number / 1000000)}M`
    )
  }

  if (number <= 1000000000 && number >= -10000000000) {
    return (
      `${Intl.NumberFormat('en-US', {
        maximumFractionDigits: asFloat ? 1 : 0,
      }).format(number / 1000000)}M`
    )
  }

  return (
    `${Intl.NumberFormat('en-US', {
      maximumFractionDigits: 0,
    }).format(number / 1000000)}M`
  )
}

export function formattedDollarValue(number) {
  return formattedNumber(number)
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export default function (app) {
  app.config.globalProperties.$capitalize = (string) => {
    return capitalize(string)
  }

  app.config.globalProperties.$displayBalance = (balance) => {
    const nonDecimalDigits = `${Math.floor(balance)}`.length

    return new Intl.NumberFormat('en-US', {
      minimumIntegerDigits: nonDecimalDigits,
      maximumSignificantDigits: nonDecimalDigits + 4,
    }).format(balance)
  }

  app.config.globalProperties.$updateUrlParams = function ($params) {
    const urlParams = new URLSearchParams(window.location.search)

    Object.keys($params).forEach((key) => {
      if ($params[key] === null) {
        urlParams.delete(key)
      }
      else {
        urlParams.set(key, $params[key])
      }
    })

    history.replaceState(null, null, `?${urlParams.toString()}`)
  }

  app.config.globalProperties.$maximumFractionDigits = (value) => {
    value = Number.parseFloat(value)

    if (value == 0) {
      return 0
    }

    let maximumFractionDigits = 6

    if (!value) {
      return maximumFractionDigits
    }

    if (value > 10 || value < -10) {
      maximumFractionDigits = 4
    }

    if (value > 100 || value < -100) {
      maximumFractionDigits = 2
    }

    if (value > 10000 || value < -10000) {
      maximumFractionDigits = 0
    }

    return maximumFractionDigits
  }

  app.config.globalProperties.$kFormatter = (count, withAbbr = true, decimals = 2) => {
    if (count === 0) {
      return count
    }

    if (count > -1 && count < 1) {
      return Number.parseFloat(count).toFixed(decimals + 1)
    }

    const COUNT_ABBRS = ['', 'K', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y']

    const temp_count = count < 0 ? count * -1 : count

    const i = temp_count === 0 ? temp_count : Math.floor(Math.log(temp_count) / Math.log(1000))
    let result = Number.parseFloat((temp_count / 1000 ** i).toFixed(decimals))

    if (count < 0) {
      result *= -1
    }

    if (withAbbr) {
      result += `${COUNT_ABBRS[i]}`
    }

    return result
  }

  app.config.globalProperties.$numberFormatter = (
    number,
    format = 'number',
    maxDigits = undefined,
    suffix = null,
  ) => {
    let formattedNumber

    if (format === 'currency') {
      formattedNumber = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits:
                    maxDigits
                    ?? app.config.globalProperties.$maximumFractionDigits(Number.parseFloat(number)),
      }).format(number)
    }
    else {
      formattedNumber = new Intl.NumberFormat('en-US', {
        maximumFractionDigits:
                    maxDigits
                    ?? app.config.globalProperties.$maximumFractionDigits(Number.parseFloat(number)),
      }).format(number)
    }

    if (suffix) {
      formattedNumber += ` ${suffix}`
    }

    return formattedNumber
  }

  app.config.globalProperties.$stableCoinTickers = [
    'usd',
    'usdt',
    'usdt.e',
    'usdc',
    'usdc.e',
    'busd',
    'dai',
    'tusd',
    'usdt-bep20',
  ]

  app.config.globalProperties.$isStableCoinTicker = (ticker, $page) => {
    if (!ticker) {
      return false
    }

    if ($page && $page.props && $page.props.stableCoinTickers) {
      return $page.props.stableCoinTickers.includes(ticker.toLowerCase())
    }

    return app.config.globalProperties.$stableCoinTickers.includes(ticker.toLowerCase())
  }

  app.config.globalProperties.$formattedNumber = formattedNumber

  app.config.globalProperties.$formattedDollarValue = formattedDollarValue

  app.config.globalProperties.$fullNumber = (number) => {
    // make sure to cast to string first
    number = `${number}`

    if (number.length !== number.replace('M', '').length) {
      return number.replace('M', '') * 1000000
    }

    if (number.length !== number.replace('k', '').length) {
      return number.replace('k', '') * 1000
    }

    return number
  }

  app.config.globalProperties.$formatDollars = formatDollars

  app.config.globalProperties.$formatDate = (dateString, $page = null) => {
    return formatInTimeZone(
      new Date(dateString),
      $page?.props.user.timezone ?? 'UTC',
      'yyyy-MM-dd HH:mm:ss',
    )
  }

  app.config.globalProperties.$selectText = selectText

  app.config.globalProperties.$scrollTo = (element) => {
    const mainContainer = document.getElementById('main')
    const toolbar = document.getElementById('toolbar')

    setTimeout(() => {
      nextTick(() => {
        mainContainer.scrollTop
                    = window.pageYOffset + element.getBoundingClientRect().top - toolbar.offsetHeight
      })
    }, 1)
  }

  app.config.globalProperties.$findMaximumDecimalCount = (array, key, min = 0) => {
    let maximumDecimalCount = min

    array.forEach((item) => {
      let newDecimalCount

      if (key && item[key] !== undefined) {
        newDecimalCount = (`${item[key]}`).split('.')[1]
      }
      else {
        newDecimalCount = (`${item}`).split('.')[1]
      }

      if (newDecimalCount?.length > maximumDecimalCount) {
        maximumDecimalCount = newDecimalCount.length
      }
    })

    return maximumDecimalCount
  }
}

import { z } from 'zod'
import { customDateSchema } from '../common'

export const CapitalizationRecord = z.object({
  name: z.string(),
  category: z.string().nullable(),
  allocation: z.number(),
  costBasisPerToken: z.number(),
  unlockStart: customDateSchema,
  unlockEnd: customDateSchema,
  unlockFrequency: z.number().min(1),
  initialUnlock: z.number(),
  potentialSeller: z.boolean(),
})

export const CapitalizationRecordStringDate = z.object({
  name: z.string(),
  category: z.string().nullable(),
  allocation: z.number(),
  costBasisPerToken: z.number(),
  unlockStart: z.string(),
  unlockEnd: z.string(),
  unlockFrequency: z.number().min(1),
  initialUnlock: z.number(),
  potentialSeller: z.boolean(),
})

export type CapitalizationRecordStringDateType = z.infer<
  typeof CapitalizationRecordStringDate
>

export const ValueInCirculationSchema = z.object({
  totalTokenSupply: z.number(),
  dateOfTGE: customDateSchema,
  startDate: customDateSchema,
  endDate: customDateSchema,
  anticipatedReturn: z.number(),
  priceAtTGE: z.number(),
  estimatedLiquidity: z.number(),
  estimatedGrowth: z.number(),
  capitalization: z.array(
    CapitalizationRecord.omit({
      category: true,
    }),
  ),
})

export const ValueInCirculationResultBody = z.object({
  date: z.date(),
  valueInCirculation: z.number(),
  costBasis: z.number(),
})

export const ValueInCirculationResultSchema = z.object({
  data: z.array(ValueInCirculationResultBody),
  generalInformation: z.object({
    firstDay: z.object({
      valueInCirculation: z.number(),
      costBasis: z.number(),
      percentDifference: z.number(),
    }),
    firstWeek: z.object({
      valueInCirculation: z.number(),
      costBasis: z.number(),
      percentDifference: z.number(),
    }),
    firstMonth: z.object({
      valueInCirculation: z.number(),
      costBasis: z.number(),
      percentDifference: z.number(),
    }),
    firstYear: z.object({
      valueInCirculation: z.number(),
      costBasis: z.number(),
      percentDifference: z.number(),
    }),
  }),
})

export const CapitalizationSchema = CapitalizationRecord.omit({
  category: true,
  costBasisPerToken: true,
  potentialSeller: true,
})

export const TCirculatingSupplyBody = z.object({
  totalTokenSupply: z.number(),
  dateOfTGE: customDateSchema,
  frequency: z.enum(['daily', 'weekly', 'monthly', 'quarterly', 'annually']),
  priceAtTGE: z.number(),
  startDate: customDateSchema,
  endDate: customDateSchema,
  capitalization: z.array(CapitalizationSchema),
})

export const TokenAllocationSchema = z.object({
  totalTokenSupply: z.number(),
  dateOfTGE: customDateSchema,
  priceAtTGE: z.number(),
  startDate: customDateSchema,
  endDate: customDateSchema,
  capitalization: z.array(CapitalizationSchema),
})

export const TokenAllocationResult = z.object({
  data: z.array(
    z.object({
      name: z.string(),
      vestedTokens: z.number(),
    }),
  ),
  generalInformation: z.object({
    circulatingSupplyAtTGE: z.number(),
    marketCapAtTGE: z.number(),
    totalTokenSupply: z.number(),
    fullyDilutedValuationAtTGE: z.number(),
  }),
})

export const TVestingEntry = z.object({
  date: z.date(),
  tokens: z.number(),
})

export const TvestingResultObject = z.object({
  name: z.string(),
  entries: z.array(TVestingEntry),
})

export const TVestingResult = z.object({
  data: z.array(TvestingResultObject),
  generalInformation: z.object({
    circulatingSupplyAtTGE: z.number(),
    marketCapAtTGE: z.number(),
    totalTokenSupply: z.number(),
    fullyDilutedValuationAtTGE: z.number(),
  }),
})

export const SellPressureUSDResultBody = z.object({
  date: z.string(),
  sellPressureByGroupUSD: z.record(z.number()),
  totalSellPressureUSD: z.number(),
})

export const SellPressureUSDResultSchema = z.object({
  data: z.array(SellPressureUSDResultBody),
  generalInformation: z.object({
    TotalSellPressure1day: z.number(),
    TotalSellPressure7day: z.number(),
    TotalSellPressure30day: z.number(),
    TotalSellPressure1year: z.number(),
  }),
})

// Sell Pressure
export const SellPressureSchema = z.object({
  totalTokenSupply: z.number(),
  dateOfTGE: customDateSchema,
  frequency: z
    .enum(['daily', 'weekly', 'monthly', 'quarterly', 'annually'])
    .default('daily'),
  startDate: customDateSchema,
  endDate: customDateSchema,
  anticipatedReturn: z.number(),
  estimatedLiquidity: z.number(),
  estimatedGrowth: z.number(),
  priceAtTGE: z.number(),
  capitalization: z.array(CapitalizationRecord.omit({ category: true })),
})

// Price vs Market Cap
export const PriceVsMarketCapSchema = z.object({
  totalTokenSupply: z.number(),
  dateOfTGE: customDateSchema,
  startDate: customDateSchema,
  endDate: customDateSchema,
  anticipatedReturn: z.number(),
  estimatedLiquidity: z.number(),
  estimatedGrowth: z.number(),
  priceAtTGE: z.number(),
  capitalization: z.array(CapitalizationRecord.omit({ category: true })),
})

export const PriceVsMarketCapResultBody = z.object({
  date: z.string(),
  tokenPrice: z.number(),
  marketCap: z.number(),
})

export const PriceVsMarketCapResultSchema = z.object({
  data: z.array(PriceVsMarketCapResultBody),
  generalInformation: z.object({
    MarketCapChange1day: z.number(),
    MarketCapChange7day: z.number(),
    MarketCapChange30day: z.number(),
    MarketCapChange1year: z.number(),
  }),
})

// Post Tokenomics
export const PostTokenomicsSchema = z.object({
  projectId: z.string(),
  totalTokenSupply: z.number(),
  dateOfTGE: z.string(),
  priceAtTGE: z.number(),
  anticipatedReturn: z.number(),
  estimatedLiquidity: z.number(),
  estimatedGrowth: z.number(),
  capitalization: z.array(
    CapitalizationRecordStringDate.omit({ category: true }),
  ),
})

export const PostTokenomicsResult = z.object({
  id: z.string(),
  projectId: z.string(),
  totalTokenSupply: z.number(),
  dateOfTGE: z.date(),
  priceAtTGE: z.number(),
  anticipatedReturn: z.number(),
  estimatedLiquidity: z.number(),
  estimatedGrowth: z.number(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullable(),
  capitalizations: z.array(
    z.object({
      id: z.string(),
      tokenomicsId: z.string(),
      name: z.string(),
      allocation: z.number(),
      costBasisPerToken: z.number(),
      unlockStart: z.date(),
      unlockEnd: z.date(),
      unlockFrequency: z.number().min(1),
      initialUnlock: z.number(),
      potentialSeller: z.boolean(),
      createdAt: z.date(),
      updatedAt: z.date(),
      deletedAt: z.date().nullable(),
    }),
  ),
})

export const TEmissionScheduleTemplates = z.record(
  z.array(CapitalizationRecordStringDate),
)

export const PriceChangeMCFDVBody = z.object({
  date: z.string(),
  priceChangePercentage: z.number(),
  MCFDVRatio: z.number(),
})

export const PriceChangeMCFDVResult = z.object({
  data: z.array(PriceChangeMCFDVBody),
  generalInformation: z.object({
    FDVChange1Day: z.number(),
    FDVChange7Day: z.number(),
    FDVChange30Day: z.number(),
    FDVChange1year: z.number(),
  }),
})

export const SummaryResult = z.array(
  z.object({
    date: z.string(),
    tokenPrice: z.number(),
    pricePercentageChange: z.number(),
    fullyDilutedValuation: z.number(),
    marketCap: z.number(),
    marketCapPercentageChange: z.number(),
    circulatingSupply: z.number(),
    MCvsFDVPercentage: z.number(),
  }),
)

export const DownloadFileResponse = z.any()

export type TEmissionScheduleTemplatesType = z.infer<
  typeof TEmissionScheduleTemplates
>

export type CapitalizationRecordType = z.infer<typeof CapitalizationRecord>
export type TCirculatingSupplyBodyType = z.infer<typeof TCirculatingSupplyBody>
export type CapitalizationSchemaType = z.infer<typeof CapitalizationSchema>

export type TvestingResultObjectType = z.infer<typeof TvestingResultObject>
export type TVestingEntryType = z.infer<typeof TVestingEntry>
export type TVestingResultType = z.infer<typeof TVestingResult>

export type TokenAllocationSchemaType = z.infer<typeof TokenAllocationSchema>
export type TokenAllocationResultType = z.infer<typeof TokenAllocationResult>

export type ValueInCirculationSchemaType = z.infer<
  typeof ValueInCirculationSchema
>
export type ValueInCirculationResultBodyType = z.infer<
  typeof ValueInCirculationResultBody
>
export type ValueInCirculationResultType = z.infer<
  typeof ValueInCirculationResultSchema
>

export type SellPressureSchemaType = z.infer<typeof SellPressureSchema>
export type SellPressureUSDResultBodyType = z.infer<
  typeof SellPressureUSDResultBody
>
export type SellPressureUSDResultType = z.infer<
  typeof SellPressureUSDResultSchema
>

export type PriceVsMarketCapSchemaType = z.infer<typeof PriceVsMarketCapSchema>
export type PriceVsMarketCapResultBodyType = z.infer<
  typeof PriceVsMarketCapResultBody
>
export type PriceVsMarketCapResultType = z.infer<
  typeof PriceVsMarketCapResultSchema
>

export type PostTokenomicsResultType = z.infer<typeof PostTokenomicsResult>

export type PriceChangeMCFDVBodyType = z.infer<typeof PriceChangeMCFDVBody>
export type PriceChangeMCFDVResultType = z.infer<typeof PriceChangeMCFDVResult>

export type SummaryResultType = z.infer<typeof SummaryResult>

import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import {
  GetActiveMarketMakerDetailsQuery,
  GetActiveMarketMakerDetailsResult,
  PostMarketMakerResult,
  PostMarketMakerSchema,
} from '../types'

const c = initContract()

export const marketMakerContract = c.router({
  postMarketMaker: {
    method: 'POST',
    path: `market-maker`,
    responses: {
      201: PostMarketMakerResult,
    },
    body: PostMarketMakerSchema,
    summary: 'Create market maker request',
  },
  getMarketMakerByProjectId: {
    method: 'GET',
    path: `market-maker`,
    responses: {
      200: PostMarketMakerResult,
    },
    query: z.object({
      projectId: z.string({ required_error: 'projectId is required' }).uuid(),
    }),
    summary: 'Get market maker by project id',
  },
  getActiveMarketMakerDetails: {
    method: 'GET',
    path: `market-maker/details`,
    responses: {
      200: GetActiveMarketMakerDetailsResult,
      204: z.undefined(),
    },
    query: GetActiveMarketMakerDetailsQuery,
    summary: 'Get market maker details',
  },
})

export type MarketMakerContractType = typeof marketMakerContract

import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import {
  TProject,
  TProjectCreateBody,
  TProjects,
  TUpdateProjectBody,
} from '../types'

const c = initContract()

export const projectsContract = c.router({
  getProjects: {
    method: 'GET',
    path: `projects`,
    responses: {
      200: TProjects,
    },
    query: z.object({
      organizationId: z.string().uuid(),
      page: z.number().default(1),
      limit: z.number().default(10),
    }),
    summary: 'Get the current user projects',
  },
  postProject: {
    method: 'POST',
    path: `projects`,
    responses: {
      201: TProject,
    },
    body: TProjectCreateBody,
    summary: 'Create a project',
  },
  updateProject: {
    method: 'PATCH',
    path: `projects/:projectId`,
    responses: {
      200: TProject,
    },
    pathParams: z.object({
      projectId: z.string().uuid(),
    }),
    body: TUpdateProjectBody,
    summary: 'Update project details',
  },
  updateProjectImage: {
    method: 'PATCH',
    path: `projects/:projectId/update-image`,
    responses: {
      204: z.undefined(),
    },
    pathParams: z.object({
      projectId: z.string().uuid(),
    }),
    body: z.object({
      image: z.string(),
    }),
    summary: 'Update a project image',
  },
})

export type ProjectsContractType = typeof projectsContract

import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import {
  ExchangeDataSchema,
  PostListingLiquidity,
  PostListingLiquidityResponse,
} from '../types/listing-liquidity'

const c = initContract()

export const listingLiquidityContract = c.router({
  getKPIs: {
    method: 'GET',
    path: `listing-liquidity/kpi`,
    responses: {
      200: z.array(ExchangeDataSchema),
    },
    summary: 'Get KPIs for listing liquidity exchanges',
  },
  post: {
    method: 'POST',
    path: `listing-liquidity`,
    responses: {
      201: PostListingLiquidityResponse,
    },
    summary: 'Create a new listing liquidity',
    body: PostListingLiquidity,
  },
  getAll: {
    method: 'GET',
    path: `listing-liquidity`,
    query: z.object({
      projectId: z.string({ required_error: 'projectId is required' }).uuid(),
    }),
    responses: {
      200: z.array(PostListingLiquidityResponse),
    },
    summary: 'Get all listing liquidity',
  },
})

export type ListingLiquidityContractType = typeof listingLiquidityContract

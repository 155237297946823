import { z } from 'zod'
import { LeadSourceTypeEnum } from '../../supabase'

export const TUpdateUser = z.object({
  firstName: z.string().nullable().optional(),
  lastName: z.string().nullable().optional(),
  telegram: z.string().nullable().optional(),
  leadSource: z.nativeEnum(LeadSourceTypeEnum).nullable().optional(),
  isGuidedTourEnabled: z.boolean().optional(),
})

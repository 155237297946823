import { initContract } from '@ts-rest/core'
import {
  GetCoinDetailedInfoResponseBody,
  SearchCoinsResponseBody,
  TCryptosInfo,
} from '../types'
import { z } from 'zod'

const c = initContract()

export const coinGeckoContract = c.router({
  getCoins: {
    method: 'GET',
    path: `coins`,
    responses: {
      200: TCryptosInfo,
    },
    summary: 'Get coins',
  },
  searchCoins: {
    method: 'GET',
    path: `coins/search`,
    query: z.object({
      query: z.string().min(1),
    }),
    responses: {
      200: SearchCoinsResponseBody,
    },
    summary: 'Search for coins.',
  },
  getCoinInfo: {
    method: 'GET',
    path: `coins/:id`,
    pathParams: z.object({
      id: z.string().min(1),
    }),
    responses: {
      200: GetCoinDetailedInfoResponseBody,
    },
    summary: 'Get coin detailed info by coin id.',
  },
})

export type CoinGeckoContractType = typeof coinGeckoContract

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent, h } from 'vue'
import { defineUi, getContent, getHandlers, type IconName, modifyProps } from '~/utils/nuxt-ui'
import { UButton } from '#components'

const Base = UButton

const eventNames = ['click']

// define the ui
const ui = defineUi('button', {
  rounded: '  rounded-lg',
  padding: {
    xs: 'py-1 px-2',
    sm: 'py-1.5 px-2',
    md: 'py-[10px] px-3',
    lg: 'py-[15px] px-5',
    xl: 'py-[18px] px-10',
  },
  variant: {
    solid: `  bg-primary-900 text-neutral-100
              hover:bg-primary-500 hover:text-neutral-100
              disabled:bg-neutral-300 disabled:ring-neutral-600 disabled:text-neutral-600
            `,
    outline: `ring-neutral-600 text-primary-900
              hover:bg-neutral-600/30 hover:ring-neutral-900
              disabled:bg-neutral-600/30 disabled:ring-neutral-600 disabled:text-neutral-600
              `,

    ghost: `  bg-transparent text-primary-900
              hover:bg-neutral-600/30 hover:text-primary-900
              disabled:text-neutral-600 disabled:cursor-no-entry disabled:hover:bg-transparent
            `,
  },
})

export default defineComponent({
  props: {
    variant: {
      type: String as PropType<'solid' | 'outline' | 'ghost'>,
      default: 'outline',
    },
    size: {
      type: String as PropType<'xs' | 'sm' | 'md' | 'lg' | 'xl'>,
      default: 'md',
    },
    label: {
      type: String,
    },
    icon: {
      type: String as PropType<IconName>,
    },
    tooltip: { // used when icon only
      type: String,
    },
    loading: {
      type: Boolean,
    },
    trailing: {
      type: Boolean,
    },
    to: {
      type: String,
    },
    block: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: eventNames,

  setup(props, { attrs, slots, emit }) {
    return () => {
      // button content
      const content = getContent(slots, props, 'label')

      // icon only
      const iconOnly = props.icon && !content.value

      // tooltip for icon only
      const tooltip = iconOnly && !props.disabled && !props.loading
        ? props.tooltip
        : undefined

      // classes
      const classes = {
        // make aria-label behave as a tooltip
        tooltip,

        // adjust loading icons
        '!opacity-100 !text-neutral-100 !bg-primary-500': props.loading && props.variant === 'solid',
        '!opacity-100 !text-primary-900': props.loading && props.variant.match(/outline|ghost/),
      }

      // modify final props
      const modified = modifyProps<typeof Base>(props, (props) => {
        if (iconOnly) {
          props.square = true
        }

        if (!props.icon && props.loading) {
          props.trailing = true
        }

        if (props.loading) {
          props.loadingIcon = props.variant === 'solid'
            ? 'i-loading-icon-light'
            : 'i-loading-icon'
        }

        // BUG: links not working in storybook
        if (props.to?.startsWith('https:')) {
          props.target = '_blank'
        }

        // return
        return props
      })

      // final options
      const options = {
        ...attrs,
        ...modified,
        ...getHandlers(eventNames, emit),
        'data-ui': 'UiButton',
        'aria-label': tooltip,
        'class': classes,
        ui,
      }

      // return
      return h(Base, options, () => content.value)
    }
  },
})
</script>

import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import { Organization, Organizations, TCountriesTemplates } from '../types'

const c = initContract()

export const organizationsContract = c.router({
  getOrganizations: {
    method: 'GET',
    path: `organizations`,
    responses: {
      200: Organizations,
    },
    query: z.object({
      page: z.number().default(1),
      limit: z.number().default(10),
    }),
    summary: 'Get the current user organizations',
  },
  getTimeZones: {
    method: 'GET',
    path: `organizations/timezones`,
    responses: {
      200: z.array(z.string()),
    },
    summary: 'Get timezones',
  },
  getCountries: {
    method: 'GET',
    path: `organizations/countries`,
    responses: {
      200: TCountriesTemplates,
    },
    summary: 'Get countries',
  },
  updateOrganizationTimezone: {
    method: 'PATCH',
    path: `organizations/timezones`,
    responses: {
      201: Organization,
    },
    body: z.object({
      timeZone: z.string(),
    }),
    summary: 'Update the current organization timezone',
  },
  updateOrganizationCountry: {
    method: 'PATCH',
    path: `organizations/countries`,
    responses: {
      201: Organization,
    },
    body: z.object({
      country: z.string(),
    }),
    summary: 'Update the current organization country',
  },
})

export type OrganizationsContractType = typeof organizationsContract

import { z } from 'zod'
import { TypeID } from 'typeid-js'

export const ProfileDetails = z.object({
  name: z.string(),
  ticker: z.string(),
  website: z.string(),
  image: z.string(),
  similarProjects: z.array(z.string()).optional(),
})
export type ProfileDetails = z.infer<typeof ProfileDetails>

export const Statements = z.object({
  problem: z.string(),
  solution: z.string(),
})
export type Statements = z.infer<typeof Statements>

export const MissionVision = z.object({
  mission: z.string(),
  vision: z.string(),
})
export type MissionVision = z.infer<typeof MissionVision>

export const ValueCreation = z.object({
  valueCreation: z.string(),
})
export type ValueCreation = z.infer<typeof ValueCreation>

export const BusinessModel = z.object({
  revenueFrom: z.string(),
  revenueDenominatedIn: z.string(),
  revenueTo: z.string(),
})
export type BusinessModel = z.infer<typeof BusinessModel>

export const EcosystemUser = z.object({
  name: z.string(),
  description: z.string(),
  task: z.string(),
  motivation: z.string(),
  valueCreation: z.string(),
  desiredBehavior: z.string(),
  incentives: z.string(),
  mechanism: z.string(),
  sideEffect: z.string(),
})
export type EcosystemUser = z.infer<typeof EcosystemUser>

export const ValueCapture = z.object({
  valueCaptureProtocol: z.string(),
  valueCaptureToken: z.string(),
})
export type ValueCapture = z.infer<typeof ValueCapture>

const tokenProfileSchemaMap = {
  basicinformation: ProfileDetails,
  problemandsolution: Statements,
  missionvision: MissionVision,
  valuecreation: ValueCreation,
  businessmodel: BusinessModel,
  productmarketfit: z.array(EcosystemUser),
  valuecapture: ValueCapture,
}

export const TokenProfileFieldsSchema = z.union([
  ProfileDetails,
  Statements,
  MissionVision,
  ValueCreation,
  BusinessModel,
  z.array(EcosystemUser),
  ValueCapture,
])
export type TokenProfileFieldsSchema = z.infer<typeof TokenProfileFieldsSchema>

const validTokenProfileSubsectiondIds = z.union([
  z.literal('basicinformation'),
  z.literal('problemandsolution'),
  z.literal('missionvision'),
  z.literal('valuecreation'),
  z.literal('businessmodel'),
  z.literal('productmarketfit'),
  z.literal('valuecapture'),
])

export const PostTokenProfileBody = z.object({
  subSectionId: z.string(),
  fields: TokenProfileFieldsSchema,
})

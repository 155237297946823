import type { ClientInferResponseBody } from '@ts-rest/core'
import type { contract } from '@forgd/common'

type Projects = ClientInferResponseBody<
  typeof contract.projects.getProjects,
  200
>
export type User = ClientInferResponseBody<typeof contract.me, 200>
export type Organization = Omit<User['organizations'][number], 'projects'>
export type Project = User['organizations'][number]['projects'][number]

export const useAuth = defineStore('auth', () => {
  const client = useClient()

  const loggedIn = ref<boolean | null>(null)
  const me = ref<User | null>(null)
  const organizations = ref<(Organization & { projects: Project[] })[] | null>(null)
  const organization = ref<(Organization & { projects: Project[] }) | null>(null)
  const project = ref<Project | null>(null)
  const ticker = computed(() => project.value?.ticker?.toUpperCase())
  const activeProjectId = computed({
    get() {
      return import.meta.client ? window.localStorage.getItem('forgd-project') : null
    },
    set(val) {
      if (val) {
        window.localStorage.setItem('forgd-project', val)
      }
      else {
        window.localStorage.removeItem('forgd-project')
      }
    },
  })

  const shouldAutoLogin = ref(true)
  // if this exists then we're authenticating
  const authCheckPromise: Ref<Promise<boolean> | null> = ref(null)

  async function doAuthFetch() {
    const mePayload = await client.me()
      .catch((err) => {
        console.error('Auth failed', err)
        loggedIn.value = false
        if (import.meta.client) {
          window.localStorage.removeItem('forgd-swr-auth-payload')
        }
        return false
      })
    if (mePayload.status !== 200) {
      console.error('Auth failed', mePayload)
      return false
    }

    loggedIn.value = true
    me.value = mePayload.body
    organizations.value = (me.value.organizations || []) as any as (Organization & { projects: Project[] })[]

    // we can hydrate from saved state
    if (activeProjectId.value) {
      for (const org of organizations.value || []) {
        for (const p of org.projects || []) {
          if (p.id === activeProjectId.value) {
            project.value = p
            organization.value = org
          }
        }
      }
    }
    // otherwise we hydrate to the first org / project
    if (!organization.value && organizations.value) {
      organization.value = organizations.value[0]
    }
    if (!project.value && organization.value.projects?.length) {
      project.value = organization.value.projects[0]
      activeProjectId.value = project.value.id
    }

    if (import.meta.client) {
      // set swr payload
      window.localStorage.setItem('forgd-swr-auth-payload', JSON.stringify({
        me: me.value,
        organizations: organizations.value,
        project: project.value,
      }))
    }
    return true
  }

  // opt-in swr hydration of auth payload
  async function check(options?: { swr?: boolean, onFailure?: () => Promise<void> | void }) {
    // avoid multiple auth checks
    return authCheckPromise.value = authCheckPromise.value || new Promise<boolean>((resolve) => {
      // we apply SWR logic to authentication
      if (options?.swr && import.meta.client) {
        // hydrate from payload
        const authPayload = window.localStorage.getItem('forgd-swr-auth-payload')
        if (authPayload) {
          const { me: _me, organizations: _organizations, project: _project } = JSON.parse(authPayload)
          me.value = _me
          organizations.value = _organizations || []
          organization.value = _organizations?.[0] || null
          project.value = _project
          loggedIn.value = true
          // do the auth check async, don't block the user
          doAuthFetch().then((res) => {
            !res && options?.onFailure?.()
          })
          return resolve(true)
        }
      }
      doAuthFetch().then(async (res) => {
        !res && await options?.onFailure?.()
        resolve(res)
      })
    }).finally(() => {
      authCheckPromise.value = null
    })
  }

  /**
   * Note: this needs to be called in addition to the Supabase signOut function
   */
  function logout() {
    loggedIn.value = null
    me.value = null
    project.value = null
    shouldAutoLogin.value = false
    activeProjectId.value = null
    if (import.meta.client) {
      window.localStorage.removeItem('forgd-swr-auth-payload')
    }
    navigateTo('/login')
  }

  function switchProject(newProject: Projects) {
    activeProjectId.value = newProject.id
    project.value = newProject
    // ensure a reload uses the right project/ uath
    window.localStorage.setItem('forgd-swr-auth-payload', JSON.stringify({
      me: me.value,
      organizations: organizations.value,
      project: project.value,
    }))
  }

  const redirectTo = ref<string | null>(null)

  return {
    organization,
    organizations,
    check,
    switchProject,
    loggedIn,
    me,
    project,
    activeProjectId,
    shouldAutoLogin,
    ticker,
    redirectTo,
    logout,
  }
})

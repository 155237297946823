import { z } from 'zod'
import { customDateSchema } from '../common'

const ListingsSchema = z.object({
  performance: z.number(),
  accessibility: z.number(),
  reach: z.number(),
  costToList: z.number(),
  liquidityReq: z.number(),
  volumeReq: z.number(),
})

export const ExchangeDataSchema = z.object({
  exchange: z.string(),
  id: z.string(),
  name: z.string(),
  image: z.string(),
  dex: z.boolean(),
  volumeSupported: z.boolean(),
  coingeckoId: z.string(),
  primaryListings: ListingsSchema,
  secondaryListings: ListingsSchema,
  monthlyVisits: z.number(),
  northAmerica: z.number(),
  southAmerica: z.number(),
  europe: z.number(),
  middleEast: z.number(),
  africa: z.number(),
  oceania: z.number(),
  asia: z.number(),
  country1: z.string(),
  country2: z.string(),
  country3: z.string(),
  country4: z.string(),
  country5: z.string(),
  description: z.string(),
  commentary: z.string(),
})

export const ExchangeDataArraySchema = z.array(ExchangeDataSchema)

const RegionEnum = z.enum([
  'Africa',
  'Asia',
  'Europe',
  'North America',
  'Middle East',
  'Oceania',
  'South America',
])

export const PostListingLiquidity = z.object({
  projectId: z.string(),
  timelineToList: customDateSchema,
  preferredRegions: z.array(RegionEnum).refine(
    (array) => {
      const uniqueSet = new Set(array)
      return uniqueSet.size === array.length
    },
    {
      message: 'preferredRegions must not contain duplicate values',
    },
  ),
  estimatedBudget: z.number().nonnegative(),
  flexibleBudget: z.boolean(),
  name: z.string(),
  ticker: z.string().optional(),
  website: z.string().optional(),
  dateOfTGE: customDateSchema,
  description: z.string(),
})

export const PostListingLiquidityResponse = z.object({
  id: z.string(),
  projectId: z.string(),
  name: z.string(),
  ticker: z.string().optional(),
  website: z.string().optional(),
  dateOfTGE: z.string(),
  description: z.string(),
  timelineToList: z.string(),
  preferredRegions: z.array(z.string()),
  estimatedBudget: z.number(),
  flexibleBudget: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string(),
  deletedAt: z.string().nullable(),
})

export type PostListingLiquidityType = z.infer<typeof PostListingLiquidity>

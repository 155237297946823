import { initClient } from '@ts-rest/core'
import { contract } from '@forgd/contract'

export function useClient() {
  const runtimeConfig = useRuntimeConfig()
  const baseUrl = process.server ? runtimeConfig.public.apiUrl : '/proxy/'

  const cookie = useCookie<string>(runtimeConfig.public.cookie)
  const headers: Record<string, string> = process.server
    ? {
        cookie: `${runtimeConfig.public.cookie}=${cookie.value}`,
      }
    : {}

  return initClient(contract, {
    baseUrl,
    baseHeaders: headers,
    credentials: 'include',
  })
}

import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const app = nuxtApp.vueApp
  const router = useRouter()
  if (!import.meta.dev) {
    Sentry.init({
      app,
      dsn: 'https://21f657cf654bc7b38e783c25013660d5@o4507695773712384.ingest.us.sentry.io/4507737742508032',
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for tracing.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
      tracePropagationTargets: [/^https:\/\/api\.forgd\.com/, /^https:\/\/dev-api\.forgd\.com/],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    })
  }
})

import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import {
  ADSummary,
  CapitalizationSchemas,
  CapitalizationSchemasWithAmount,
  PostADBody,
  PostADResult,
  RecipientBody,
  RecipientResponseBody,
} from '../types'

const c = initContract()

export const autoDistributionContract = c.router({
  getADCapitalizations: {
    method: 'GET',
    path: `auto-distribution/capitalizations`,
    responses: {
      200: z.array(CapitalizationSchemasWithAmount),
    },
    query: z.object({
      tokenomicsId: z
        .string({ required_error: 'tokenomicsId is required' })
        .uuid(),
    }),
    summary: 'Get auto-distribution capitalizations',
  },
  enableADCapitalization: {
    method: 'PATCH',
    path: `auto-distribution/capitalizations/:id/enable`,
    responses: {
      200: CapitalizationSchemas,
    },
    body: z.object({
      enabled: z.boolean(),
    }),
    pathParams: z.object({
      id: z.string().uuid(),
    }),
    summary: 'Enable auto-distribution capitalization',
  },
  postADRecipient: {
    method: 'POST',
    path: `auto-distribution/recipient`,
    responses: {
      201: RecipientResponseBody,
    },
    body: RecipientBody,
    summary: 'Create auto-distribution recipient',
  },
  updateADRecipient: {
    method: 'PUT',
    path: `auto-distribution/recipient/:id`,
    responses: {
      200: RecipientResponseBody,
    },
    body: RecipientBody,
    pathParams: z.object({
      id: z.string().uuid(),
    }),
    summary: 'Update auto-distribution recipient',
  },
  deleteADRecipient: {
    method: 'DELETE',
    path: `auto-distribution/recipient/:id`,
    body: z.object({
      delete: z.boolean(),
    }),
    responses: {
      200: z.object({
        deleted: z.boolean(),
      }),
    },
    pathParams: z.object({
      id: z.string().uuid(),
    }),
    summary: 'Delete auto-distribution recipient',
  },
  postAD: {
    method: 'POST',
    path: `auto-distribution`,
    responses: {
      201: PostADResult,
    },
    body: PostADBody,
    summary: 'Create auto-distribution for review',
  },
  getAD: {
    method: 'GET',
    path: `auto-distribution`,
    responses: {
      200: PostADResult,
    },
    query: z.object({
      tokenomicsId: z
        .string({ required_error: 'tokenomicsId is required' })
        .uuid(),
    }),
    summary: 'Get auto-distribution',
  },
  getADSummary: {
    method: 'GET',
    path: `auto-distribution/summary`,
    responses: {
      200: ADSummary,
    },
    query: z.object({
      tokenomicsId: z
        .string({ required_error: 'tokenomicsId is required' })
        .uuid(),
    }),
    summary: 'Get auto-distribution summary',
  },
})

export type AutoDistributionContractType = typeof autoDistributionContract

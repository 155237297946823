import { MemberStatusEnum } from '../../supabase/supabase.enums'
import { z } from 'zod'

export enum MemberContactType {
  Email = 'email',
  Telegram = 'telegram',
}

export const MemberInvitationDto = z.object({
  firstName: z.string(),
  lastName: z.string(),
  position: z.string().optional(),
  email: z
    .string()
    .email()
    .transform((email) => email.toLowerCase()),
  organizationName: z.string().optional(),
})

export const SendInvitationRequestBody = z.object({
  organizationId: z.string(),
  members: z.array(MemberInvitationDto),
})

export const ResendInvitationRequestBody = z.object({
  organizationId: z.string(),
  memberId: z.string(),
})

export const AcceptMemberInvitationDto = MemberInvitationDto.extend({
  contactType: z.nativeEnum(MemberContactType),
  contact: z.string().min(1),
})

export const AcceptInvitationRequestBody = AcceptMemberInvitationDto.extend({
  code: z.string(),
})

export const DeleteMemberBody = z.object({
  organizationId: z.string(),
  memberId: z.string(),
})

export const OrganizationMemberDetailsBody = z.object({
  id: z.string().uuid(),
  userId: z.string().uuid().nullable(),
  organizationId: z.string().uuid(),
  firstName: z.string().trim().min(1).nullable(),
  lastName: z.string().trim().min(1).nullable(),
  email: z.string().email().nullable(),
  position: z.string().nullable(),
  status: z.nativeEnum(MemberStatusEnum),
})

export const GetMembersResponseBody = z.object({
  members: z.array(OrganizationMemberDetailsBody),
  maxSeats: z.number(),
})

export type SendInvitationRequestBody = z.infer<
  typeof SendInvitationRequestBody
>
export type ResendInvitationRequestBody = z.infer<
  typeof ResendInvitationRequestBody
>
export type AcceptInvitationRequestBody = z.infer<
  typeof AcceptInvitationRequestBody
>
export type MemberInvitationDto = z.infer<typeof MemberInvitationDto>

export type AcceptMemberInvitationDto = z.infer<
  typeof AcceptMemberInvitationDto
>

export type DeleteMemberBody = z.infer<typeof DeleteMemberBody>

export type GetMembersResponseBody = z.infer<typeof GetMembersResponseBody>

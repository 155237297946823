import { Logtail } from '@logtail/browser'

export default defineNuxtPlugin((nuxtApp) => {
  const { betterStack } = useRuntimeConfig().public
  if (!betterStack.sourceToken) {
    return
  }
  const logtail = new Logtail(betterStack.sourceToken)
  // nuxtApp.hook('vue:error', (error, instance, info) => {
  //   logtail.error('vue:error', {
  //     error,
  //     info,
  //     instance,
  //   })
  // })
  // nuxtApp.hook('app:error', (error) => {
  //   logtail.error('app:error', {
  //     error,
  //   })
  // })
  // nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
  //   logtail.error('global:error', {
  //     error,
  //     info,
  //     instance,
  //   })
  // }
})

import { z } from 'zod'

export enum ActivityStatus {
  NotStarted = 'not_started',
  InProgress = 'in_progress',
  OnHold = 'on_hold',
  Stuck = 'stuck',
  Completed = 'completed',
}

export const PostActivitiesLead = z.object({
  projectId: z.string().uuid(),
  projectName: z.string().trim().min(1),
  firstName: z.string().trim().min(1),
  lastName: z.string().trim().min(1),
  ticker: z
    .string()
    .trim()
    .min(1)
    .transform((val) => val.toUpperCase()),
  siteUrl: z.string().url().optional().nullable(),
  email: z.string().email(),
})

export type ActivitiesLeadType = z.infer<typeof PostActivitiesLead>

export const GetActivitiesLeadResult = PostActivitiesLead.extend({
  id: z.string().uuid(),
})

import { z } from 'zod'
import { TProject } from '../projects'
import {
  BaseLayerTypeArray,
  SectorTypeArray,
} from '../../supabase/supabase.enums'

export const UserResponse = z.object({
  id: z.string(),
  email: z.string(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  isOnboarded: z.boolean(),
  telegram: z.string().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date(),
  isGuidedTourEnabled: z.boolean(),
  organizations: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      ownerUserId: z.string(),
      createdAt: z.date(),
      updatedAt: z.date(),
      deletedAt: z.date(),
    }),
  ),
})

const dateTimeLocal = z.string().datetime({ local: true })

export const GetMeResponse = z.object({
  id: z.string(),
  email: z.string(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  isOnboarded: z.boolean(),
  telegram: z.string().nullable(),
  createdAt: dateTimeLocal,
  updatedAt: dateTimeLocal,
  deletedAt: dateTimeLocal.nullable(),
  isGuidedTourEnabled: z.boolean(),
  organizations: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      ownerUserId: z.string(),
      createdAt: dateTimeLocal,
      updatedAt: dateTimeLocal,
      deletedAt: dateTimeLocal.nullable(),
      projects: z.array(
        TProject.omit({
          areasOfInterest: true,
        }).extend({
          organizationId: z.string().uuid().nullable(),
          createdAt: dateTimeLocal,
          updatedAt: dateTimeLocal,
          deletedAt: dateTimeLocal.nullable(),
          tgeDate: dateTimeLocal.nullable(),
          baseLayer: z.array(z.enum(BaseLayerTypeArray)).nullable(),
          sector: z.array(z.enum(SectorTypeArray)).nullable(),
        }),
      ),
      membersCount: z
        .array(
          z.object({
            count: z.number().int().nonnegative(),
          }),
        )
        .length(1)
        .transform((data) => data[0].count),
    }),
  ),
})
